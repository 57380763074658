const currentclienthistoryTemplate = require('./CurrentClientHistory.template.html');
const BaseView = require('app/Base.view');
const Select = require('components/Select');
const _ = require('underscore');
const CC = require('CC');
const Backbone = require('backbone');
require('./CurrentClientHistory.css');

class CurrentClientHistoryView extends BaseView {
    get template() {
        return _.template(currentclienthistoryTemplate);
    }

    get defaults() {
        return {
            clientId: CC.utils.getCurrentClientId(),
            currentClientId: CC.utils.getCurrentClientId(),
            memberClientId: CC.SessionData.userInfo.get('memberClientId')
        };
    }

    get events() {
        return {
            'click .assignedClient': 'handleClick'
        };
    }

    get validation() {
        return [
            {
                name: 'roles',
                required: true
            }
        ];
    }

    initialize(options) {
        _.extend(this, this.defaults, options);

        this.validateProperties();

        this.sortCollection = new Backbone.Collection([
            {
                id: 'access-dsc',
                direction: 'descending',
                name: 'Most Recently Used',
                columnName: 'lastUsed'
            },
            {
                id: 'access-asc',
                direction: 'ascending',
                name: 'Least Recently Used',
                columnName: 'lastUsed'
            },
            {
                id: 'name-asc',
                direction: 'ascending',
                name: 'Name: A - Z',
                columnName: 'name'
            },
            {
                id: 'name-dsc',
                direction: 'descending',
                name: 'Name: Z - A',
                columnName: 'name'
            }
        ]);
        this.sortSetting = this.sortCollection.findWhere({ id: 'access-dsc' });
    }

    render() {
        const viewOptions = {
            clientRoleSet: _.sortBy(this.roles, role => {
                let value = role.client[this.sortSetting.get('columnName')];
                const direction = this.sortSetting.get('direction');

                if (this.sortSetting.get('columnName') === 'lastUsed') {
                    // For undefined lastUsed values, return a very old/future timestamp when sorting
                    if (!value) {
                        value =
                            direction === 'descending'
                                ? new Date('1900-01-01').getTime()
                                : new Date('9999-12-31').getTime();
                    } else {
                        // Convert date string to timestamp for proper numerical comparison
                        value = new Date(value).getTime();
                    }
                }

                if (typeof value === 'string') {
                    return direction === 'descending' ? -value.toLowerCase() : value.toLowerCase();
                }

                return direction === 'descending' ? -value : value;
            }),
            currentId: this.currentClientId,
            memberClientId: this.memberClientId,
            baseLogoPath: CC.utils.getBaseClientLogoPath(),
        };

        this.$el.html(this.template(viewOptions));
        this.renderSortOptions();

        return this;
    }

    renderSortOptions() {
        const sortId = this.sortSetting.get('id');

        this.createAndRenderSubView('.switch-client__sort', Select, {
            listCollection: this.sortCollection,
            width: '180px',
            allowClientSort: false,
            checkMaxResults: false,
            checkApi: false,
            showSearch: false,
            changeHandler: (event, id, model) => {
                this.sortSetting = model;
                this.render();
            }
        }).setValue(sortId);
    }

    handleClick(e) {
        const clientId = this.$(e.currentTarget).attr('data-client-id');
        const currentClient = this.roles.find(role => role.client.id == clientId).client.info;
        this.model.set('currentClient', currentClient);
    }
}

export default CurrentClientHistoryView;
