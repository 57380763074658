define(function(require) {
    const ModalTemplate = require('./BasicModal.template.html');
    const CC = require('CC');
    const _ = require('underscore');
    const $ = require('jquery');
    const BaseView = require('app/Base.view');
    require('components/Button.css');
    require('./basicModal.css');

    const basicModal = BaseView.extend({
        defaults: _.extend({}, BaseView.prototype.baseDefaults, {
            alertViews: [],
            displayMode: 'MODAL', // MODAL|PANEL
            modalId: 'myModal',
            modalTitle: 'Do Something',
            condensedFormat: undefined,
            showCancel: true,
            cancelText: 'Cancel',
            showSave: true,
            saveText: 'Save',
            saveDisabled: false,
            showClose: false,
            closeText: 'Close',
            contentView: undefined,
            footerView: undefined,
            savingText: 'Saving...',
            isSuccessful: false,
            alertType: 'danger',
            closeOnEnterKey: true,
            message: '',
            fadeDirection: 'top', // top|right
            modalAttributes: [], // [{ name, value }]
            modalSize: '', // <nothing>, sm,md,lg,xl
            modalHeight: 'md', // <nothing>, sm,md,lg,xl
            isScrollable: true, // Allow the content to show a scrollbar if necessary
            dismissOnOutsideClick: true,
            autoDismissSeconds: undefined // Automatically close the modal after # of seconds
            // scheme: undefined,
        }),

        // For Use in views that extend this view
        myDefaults: {},

        initialize: function(options) {
            const self = this;
            self._template = _.template(ModalTemplate);
            _.extend(
                self,
                {
                    scheme: CC.utils.getPageScheme().replace('page-', '')
                },
                self.defaults,
                options
            );

            if (self.scheme === 'scheme-terrene') {
                self.hasTerrene = true;
            }

            self.alertProperties = _.extend({}, BaseView.prototype.alertProperties, {
                el: `#${self.modalId}-alert`
            });

            self.listenTo(CC.Events, 'Page:enableModalSave', self.enableSaveButton);
            self.listenTo(CC.Events, 'Page:disableModalSave', self.disableSaveButton);
            self.listenTo(CC.Events, 'Page:changeModalAttribute', (key, value) => {
                this.$('.modal-dialog').attr(key, value);
            });
            this.listenTo(CC.Events, 'Page:writeModalMessage', this.renderFooterMessage);
            return self;
        },

        events: {
            'click button.save-button': 'handleSaveClick',
            'click button.cancel-button': 'handleCancelClick',
            'click button.close-button': 'handleCloseClick',
            'click button.close': 'handleCloseClick',
            'hidden.bs.modal .modal': 'handleClose',
            'keyup .modal': 'handleKeyPress'
        },

        handleSaveClick: _.debounce(
            function(event) {
                const self = this;

                const $btn = self.$(`#${self.modalId}_saveModalButton`);
                $btn.html(self.savingText);
                $btn.prop('disabled', true);
                self.$(`#${self.modalId}_cancelModalButton`).hide();

                // Go ahead and close this out we are done here
                const saveResult = self.handleSave(event);

                if (saveResult) {
                    // Only reset if the result is not an XHR
                    if (!_.isObject(saveResult)) {
                        self.closeModal();
                    }
                } else {
                    self.resetSaveButton();
                }
            },
            300,
            true
        ),

        handleCancelClick: function() {
            // Cancel Button was clicked
        },

        handleCloseClick: function() {
            // Close Button was clicked
        },

        closeModal: function(isSuccessful) {
            const self = this;
            self.isSuccessful = isSuccessful;

            // Make sure it is still here (eg. ConfirmationModal:finishDelay, tests)
            if (self.$el) {
                self.$(`#${self.modalId}`).modal('hide');
            }
        },

        handleSave: function(event) {
            // Override This
        },

        finishSave: function(alertType, alertMessage, alertIntro) {
            const self = this;
            self.renderAlert({
                alertType: alertType,
                message: alertMessage,
                intro: alertIntro,
                isAppended: true
            });

            self.$(`#${self.modalId}-content-holder`).hide();

            self.$(`#${self.modalId}_cancelModalButton`).hide();
            self.$(`#${self.modalId}_saveModalButton`).hide();
            self.$(`#${self.modalId}_closeModalButton`).show();
            self.isSuccessful = alertType === 'success';
        },

        render: function() {
            // sets up the inital modal and pops it up
            const self = this;
            const $el = $(this.el);
            const hasContentView = self.hasValue(self.contentView);
            // Typically, modals with just a message have the "condensed" format with no title but a border color.
            // This can be explicitly set by passing condensedFormat true / false into the modal constructor.
            const condensedFormat = _.isUndefined(self.condensedFormat) ? !hasContentView : self.condensedFormat;

            $el.empty().append(
                self._template({
                    modalId: self.modalId,
                    displayMode: this.displayMode,
                    modalTitle: self.modalTitle,
                    showCancel: self.showCancel,
                    cancelText: self.cancelText,
                    showSave: self.showSave,
                    saveText: self.saveText,
                    condensedFormat: condensedFormat,
                    saveDisabled: self.saveDisabled,
                    showClose: self.showClose,
                    closeText: self.closeText,
                    modalHeight: self.modalHeight,
                    modalSize: _.isEmpty(self.modalSize) ? '' : `-${self.modalSize}`,
                    scrollableClass: self.isScrollable ? 'scrollable' : 'non-scrollable',
                    scheme: self.scheme,
                    hasContentView: hasContentView,
                    hasTerrene: self.hasTerrene,
                    alertType: self.alertType,
                    fadeDirection: self.fadeDirection,
                    buttonVariant: ['danger', 'warning'].indexOf(self.alertType) !== -1 ? 'button--destructive' : '',
                    modalAttributes: this.modalAttributes.reduce((str, { name, value }) => `${name}=${value}`, '')
                })
            );
            self.$(`#${self.modalId}-content-holder`).html(self.defaultLoader);
            self.renderContentView();

            if (self.saveDisabled) {
                self.disableSaveButton();
            }

            if (self.autoDismissSeconds > 0) {
                self.updateModalFooter(self.autoDismissSeconds);
                let counter = 0;
                this.autoDismissInterval = setInterval(() => {
                    counter++;
                    self.updateModalFooter(self.autoDismissSeconds - counter);
                }, 1000);
            }
            return self;
        },

        updateModalFooter: function(seconds) {
            this.$('.modal-footer').html(
                _.template('<em>Closing in <%= seconds %> second<%- seconds === 1 ? "": "s" %>... </em')({
                    seconds: seconds
                })
            );
        },

        renderErrorMessage: function(errorMessage) {
            const self = this;
            if (self.showAlertInFooter) {
                self.clearErrors();
                if (!this.hasTerrene) {
                    self.$('.modal-footer').addClass('bg-danger');
                }
                this.renderFooterMessage(errorMessage);
            } else {
                self.clearErrors();
                self.renderAlert({
                    alterType: 'danger',
                    isPushAlert: true,
                    message: errorMessage
                });
            }
        },

        renderFooterMessage: function(message) {
            this.$(`#${this.modalId}_save_message`).html(message);
        },

        clearErrors: function() {
            const self = this;
            self.$('.modal-footer').removeClass('bg-danger');
            self.$(`#${self.modalId}_save_message`).html('');
            self.clearAlert();
        },

        openModal: function() {
            const self = this;
            // Cancelling a modal by clicking away should trigger the cancel flow
            // just as if the cancel button was clicked
            self.$(`#${self.modalId}`).on('shown.bs.modal', function onModalShown() {
                if (self.dismissOnOutsideClick) {
                    $('.basic-modal.fade').click(function(e) {
                        // Don't initiate if the cancel is coming from inside the modal itself
                        if (!$(e.target).closest('.modal-content').length) {
                            self.handleCancelClick(e);
                        }
                    });
                }
            });
            self.$(`#${self.modalId}`).modal({
                backdrop: self.dismissOnOutsideClick ? true : 'static'
            });

            if (self.autoDismissSeconds > 0) {
                this.autoDismissTimeout = _.delay(function() {
                    this.isSuccessful = true;
                    self.closeModal(true);
                }, self.autoDismissSeconds * 1000);
            }
        },

        renderContentView: function() {
            const self = this;
            if (typeof self.contentView !== 'undefined') {
                self.listenTo(
                    self.contentView,
                    'noData',
                    function(message, intro) {
                        self.finishSave('danger', message, intro);
                    },
                    self
                );
                self.listenTo(
                    self.contentView,
                    'save',
                    function() {
                        self.handleSaveClick();
                    },
                    self
                );
                self.listenTo(
                    self.contentView,
                    'cancel',
                    function() {
                        self.closeModal();
                    },
                    self
                );
                self.listenTo(
                    self.contentView,
                    'form:valid',
                    function(valid) {
                        if (valid) {
                            self.enableSaveButton();
                        } else {
                            self.disableSaveButton();
                        }
                    },
                    self
                );
                self.$(`#${self.modalId}`).on('shown.bs.modal', function onModalInDOM() {
                    self.assign(`#${self.modalId}-content-holder`, self.contentView);
                });
                self.renderFooterView();
                self.renderSaveButton();
                self.openModal();
            } else {
                self.renderAlert({
                    alertType: self.alertType,
                    message: self.message,
                    isAppend: false,
                    el: `#${self.modalId}-content-holder`
                });
                self.openModal();
            }

            return self;
        },

        renderSaveButton: function() {
            // Override to render a custom save button
        },

        renderFooterView: function() {
            const self = this;
            if (typeof self.footerView !== 'undefined') {
                self.$(`#${self.modalId}`).on('shown.bs.modal', function onModalInDOM() {
                    self.assign(`#${self.modalId}_footer_content`, self.footerView);
                });
            }

            return self;
        },

        resetSaveButton: function() {
            const self = this;
            self.$(`#${self.modalId}_saveModalButton`).html(self.saveText);
            self.$(`#${self.modalId}_cancelModalButton`).show();
            self.enableSaveButton();
        },

        enableSaveButton: function() {
            const self = this;
            self.$(`#${self.modalId}_saveModalButton`).prop('disabled', false);
        },

        disableSaveButton: function() {
            const self = this;
            self.$(`#${self.modalId}_saveModalButton`).prop('disabled', true);
        },
        onSuccessExit: function() {
            // TO Be Overridden
        },

        handleClose: function() {
            const self = this;
            if (self.isSuccessful) {
                self.onSuccessExit();
            }

            if (this.autoDismissInterval) {
                clearInterval(this.autoDismissInterval);
            }
            if (this.autoDismissTimeout) {
                clearTimeout(this.autoDismissTimeout);
            }
        },

        handleKeyPress: function(e) {
            if (e.which === 13 && this.closeOnEnterKey) {
                this.handleSaveClick(e);
            }
        }
    });
    return basicModal;
});
