define(function(require) {
    const CurrentClientSwitcherTemplate = require('./CurrentClientSwitcher.template.html');
    const ClientModel = require('models/client/Client');
    const ClientFlagsModel = require('models/client/ClientFlags');
    const BaseView = require('app/Base.view');
    const typeAheadNoResultsTemplate = require('./typeAheadNoResults.template.html');
    const CurrentClientHistoryView = require('./CurrentClientHistory.view').default;
    const ClientCollection = require('models/client/Clients.collection');
    const $ = require('jquery');
    const _ = require('underscore');
    const Backbone = require('backbone');
    const DateUtils = require('DateUtils');

    const CC = require('CC');
    require('typeAhead');
    require('./CurrentClientSwitcher.css');

    const CurrentClientSwitcherView = BaseView.extend({
        template: _.template(CurrentClientSwitcherTemplate),
        typeAheadResultsTemplate: _.template(`
        <div class="typeAheadResult" data-client-type="<%- model?.getType() %>">
            <div class="typeAheadResultContent">		
                <% if(value) { %> 
                    <div class="client-logo">
                         <span><%= model?.getShortName() %></span>
                    </div>
                    <div class="client-content">
                        <div class="typeAheadName" data-weight="medium">
                            <%- name %> [<%- value %>]
                            <% if (memberClientId == value) {%>
                                <i class="lotacon lotacon-star" />
                            <% } %> 
                        </div>
                        <div class="h12" data-weight="regular" >
                        <% if (model?.isNetwork()) { %>
                            <i class="lotacon lotacon-umbrella-line"/>
                        <% } else if (model?.getType() == "child") { %>
                            <i class="lotacon lotacon-drop-line"/>
                        <% } else { %>
                            <i class="lotacon lotacon-cloud-line"/>
                        <% } %>
                        <%- model?.getFormattedType() %>
                        </div>
                        <div class="h12" data-weight="regular">
                        <% if (roleName) { %>
                            <i class="lotacon lotacon-user-follow-line"/> </i><%- roleName %>
                        <% } %>
                    </div>
                   </div>
                   <div class="client-timestamp h12" data-weight="regular">
                    <%- lastUsedDisplay %>
                 </div>
                <% } %>                
            </div>           
        </div>
        `),
        typeAheadNoResultsTemplate: _.template(typeAheadNoResultsTemplate),

        defaults: function() {
            return {
                isAdminUser: CC.authorizeByPermission('Administration')
            };
        },

        events: {},

        initialize: function(options) {
            _.extend(this, this.defaults(), options);

            this.roleSetCollection = CC.SessionData.userInfo.get('clientRoleSet');

            this.roles = this.roleSetCollection.map(role => {
                // Look up more details from history
                const history = CC.utils.getCurrentClientHistory().find(entry => entry.value == role.client.id);
                if (history) {
                    role.client.lastUsed = history.timestamp;
                    role.client.lastUsedDisplay = DateUtils.getTimeSince(history.timestamp);
                }

                role.client.info = CC.SessionData.userOrgs.get(role.client.id);
                return role;
            });
        },

        render: async function() {
            const viewOptions = {
                isAdminUser: this.isAdminUser
            };

            this.model = new Backbone.Model({
                currentClientId: 0
            });
            this.listenTo(this.model, 'change:currentClient', this.handleChange);

            this.$el.html(this.template(viewOptions));

            if (this.isAdminUser) {
                this.renderSearch();
            }

            await this.renderClients();

            return this;
        },

        renderClients: async function() {
            this.$('.switch-client__history').html(this.defaultLoader);
            // Get unique client IDs that aren't already in roles
            const uniqueIds = new Set(
                CC.utils
                    .getCurrentClientHistory()
                    .map(history => history.value)
                    .filter(id => !this.roles.some(role => role.client.id === id))
            );

            const missingLogos = new Set(
                CC.utils
                    .getCurrentClientHistory().filter(entry => !entry.logoFileName)
                    .map(history => history.value)
            );

            // If no new clients to fetch, exit early
            if (uniqueIds.size === 0) {
                this.renderHistory();
                return;
            }

            const clientFlagCollection = Array.from(missingLogos).map(id => new ClientFlagsModel({ clientId: id }));
            const flagFetchPromises = clientFlagCollection.map(flag => 
                flag.fetch().catch(error => {
                    console.error('Error fetching client flags:', error);
                    return null;
                })
            );
            await Promise.all(flagFetchPromises);

            try {
                const clientCollection = new ClientCollection(null, {
                    queryParams: { id: Array.from(uniqueIds) }
                });

                await clientCollection.fetch();

                const newRoles = clientCollection.models.map(client => {
                    const historyEntry = CC.utils
                    .getCurrentClientHistory().find(entry => entry.value == client.get('id'));
                    return {
                    client: {
                        id: client.get('id'),
                        name: client.get('name'),
                        info: client,
                        lastUsed: historyEntry.timestamp,
                        lastUsedDisplay: DateUtils.getTimeSince(historyEntry.timestamp),
                        logoUrl: clientFlagCollection.find(flag => flag.get('clientId') == client.get('id'))?.get('logoFileName')
                    }
                }});
            

                // Batch update roles array
                this.roles.push(...newRoles);
                this.renderHistory();
            } catch (error) {
                console.error('Error fetching clients:', error);
                this.renderAlert({
                    el: '.switch-client__history',
                    message: CC.utils.getAjaxErrorMessage(error, 'Error fetching clients')
                });
            }
        },

        renderHistory: function() {
            this.createAndRenderSubView('.switch-client__history', CurrentClientHistoryView, {
                roles: this.roles,
                model: this.model
            });
        },

        renderSearch: function() {
            const clientBloodhound = new Bloodhound({
                datumTokenizer: function(datum) {
                    // eslint-disable-next-line no-undef
                    return Bloodhound.tokenizers.whitespace(datum.value);
                },
                limit: 100,
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: `${CC.apiUrl}/clients/permission?sort_attr=name&sort_order=ASC&include_child_clients=true&permission_name=View%20Reports&audience_ops=false&filter=%QUERY&page_count=100`,
                    filter: function(response) {
                        return _.map(response.Client, function(clientObj) {
                            return {
                                value: clientObj.id,
                                name: clientObj.name,
                                model: new Backbone.Model(clientObj)
                            };
                        });
                    }
                }
            });
            clientBloodhound.initialize();
            const clientSearch = this.$('#clientSearchBox');
            clientSearch.typeahead(
                {
                    hint: false,
                    highlight: true,
                    minLength: 1
                },
                [
                    {
                        name: 'clientSearch',
                        displayKey: 'value',
                        source: clientBloodhound.ttAdapter(),
                        templates: {
                            header: ['<div class="suggestionsHeader">Clients</div>'].join('\n'),
                            empty: this.typeAheadNoResultsTemplate(),
                            suggestion: function(data) {
                                const history = CC.utils
                                    .getCurrentClientHistory()
                                    .find(entry => entry.value == data.value);

                                const roleSet = this.roleSetCollection.find(role => role.client.id == data.value);
                                let lastUsedDisplay;
                                if (history) {
                                    lastUsedDisplay = DateUtils.getTimeSince(history.timestamp);
                                }
                                return this.typeAheadResultsTemplate({
                                    name: data.name,
                                    value: data.value,
                                    model: new ClientModel(data.model.attributes),
                                    memberClientId: CC.SessionData.userInfo.get('memberClientId'),
                                    lastUsedDisplay: lastUsedDisplay,
                                    roleName: roleSet ? roleSet.role?.displayName : undefined
                                });
                            }.bind(this)
                        }
                    }
                ]
            );

            clientSearch.on('typeahead:selected', (evtObj, suggestionObj, dataSetName) => {
                CC.utils.trackEvent(
                    'ClientSwitcher',
                    'NavBar - ClientSwitcher',
                    `${suggestionObj.category}:${suggestionObj.value}`
                );
                this.model.set('currentClient', suggestionObj.model);
            });
            this.$('#clientSearchBox').trigger('focus');
        },

        handleChange: function() {
            this.trigger('save');
        },

        save: function() {
            const currentClient = this.model.get('currentClient');
            // Clear any whitelabel reference
            CC.utils.storeWhiteLabelKey(undefined);

            const errorMessage = CC.utils.setCurrentClientInfo(currentClient);

            const defer = $.Deferred();
            if (!currentClient) {
                return null;
            }
            if (errorMessage) {
                defer.reject({
                    responseText: JSON.stringify({ message: errorMessage })
                });
            } else {
                this.renderAlert({
                    el: '#messageHolder',
                    message: `Switching the current client to be <strong>${currentClient.get(
                        'name'
                    )}</strong>. This may take a moment...`,
                    alertType: 'info'
                });

                this.listenToOnce(CC.Events, 'session_loaded', function() {
                    defer.resolve();
                });
                this.listenToOnce(CC.Events, 'session_error', function() {
                    defer.reject();
                });

                CC.Events.trigger('CurrentClientInfo:Changed', currentClient);
            }

            return defer;
        }
    });

    return CurrentClientSwitcherView;
});
