define(function(require) {
    const BasicFilterView = require('./BasicFilter.view');
    const filterTemplate = /*template*/ `
    <div class="select <%= isMultiSelect ? 'select--multiple' : 'select--single' %> <%= needsSearch ? 'select--search' : 'select--no-search' %>">
        <% if (label) { %>	
            <label class="select__label"><%- label %></label>
        <% } %>
        <select id="<%=name %>" class="select__select" <%= isMultiSelect ? "multiple" : "" %>>
            <% if (allowClear) { %>
            <option></option>      
            <% } %>     
        </select>
    </div>
    `;
    const $ = require('jquery');
    const _ = require('underscore');
    const Backbone = require('backbone');
    const CC = require('CC');
    require('jquerySelect2');
    require('vendor/select2-multi-checkboxes');
    require('jqueryPlugins/jquery.select2/select2.css');
    require('./Select.css');

    const MIN_ITEMS_FOR_SEARCH = 10;
    const SearchSelect = BasicFilterView.extend({
        defaults: {
            isMultiSelect: false, // Multiselect Checkboxes
            closeOnSelect: true,
            placeholder: 'Filter',
            width: '100%',
            showSearch: true,
            dataField: '',
            dataUrl: '',
            useTags: false,
            useCache: true,
            name: 'Select',
            allowClear: false, // Shows the placholder instead of choosing the first one, and allows the current selection to be cleared
            quietMillis: 550,
            minimumInputLength: 2,
            forceCleanSelections: false, // An Opt-in feature to prevent selections getting to formBuilder as an array of one item
            dataFn: function(params) {
                return {
                    name: params.term
                };
            },
            createTag: function(params, data) {
                return { id: 0, text: params.term };
            },
            // eslint-disable-next-line no-unused-vars
            changeHandler: function(event, id, model) {},
            formatSelectionFn: function(state) {
                return state.text;
            },
            processResultsFn: function(data) {
                let options = [];
                const dataList = data[this.dataField];

                if (dataList) {
                    options = _.map(dataList, function(item) {
                        return {
                            id: item.id,
                            text: item.name
                        };
                    });
                }

                return options;
            },
            autoCompleteQueryParams: ['name'],
            listCollectionIdParam: 'id', // String or fn(model)
            listCollectionNameParam: 'name', // String or fn(model)
            listCollectionGroupingParam: 'grouping', //String or fn(model)
            listCollection: undefined,
            defaultValue: undefined, // { id: <>, name: <>}
            // On first render, check to see if more than <minRows> result will ever
            // be returned and just show a span if only <m>inRows>
            checkMaxResults: false,
            minRows: 1, // Minimum rows allowed when checking max results
            noResultsMessage: 'No Results Found',
            onlyResult: undefined,
            label: undefined,
            checkApi: true
        },

        template: _.template(filterTemplate),

        initialize: function(options) {
            const self = this;
            _.extend(self, self.defaults, options);

            self.listenTo(self.listCollection, 'sync', self.processResults);
            self.listenTo(self.listCollection, 'error', self.processResults);
            self.listenTo(self, 'close', self.cleanUpSelect);
            self.isFilter = !_.isUndefined(self.collection);
            self.minimumResultsForSearch = self.showSearch ? MIN_ITEMS_FOR_SEARCH : Infinity;
            self.needsSearch = !self.listCollection || self.listCollection.length >= self.minimumResultsForSearch;

            if (self.isMultiSelect) {
                self.closeOnSelect = false;
                self.formatSelectionFn = function(data) {
                    if (!data.id) {
                        return data.text;
                    }

                    const $res = $('<div></div>');

                    $res.text(data.text);
                    $res.addClass('select2-results__text');

                    return $res;
                };
            }
        },

        render: function() {
            const self = this;

            self.elementName = self.name;
            self.elementNameSelector = `#${self.elementName}`;
            self.$el.show();

            if (self.checkMaxResults) {
                self.$el.html(self.smallLoader);
                self.initialCollection = self.listCollection.clone();
                // Query Params don't copy well
                self.initialCollection.queryParams = self.listCollection.queryParams;
                self.initialCollection.fetch({
                    success: function(collection, status, xhr) {
                        if (self.initialCollection.state.totalRecords === 1 && self.minRows > 0) {
                            const model = self.initialCollection.at(0);
                            const id = _.isFunction(self.listCollectionIdParam)
                                ? self.listCollectionIdParam(model)
                                : model.get(self.listCollectionIdParam);
                            const text = _.isFunction(self.listCollectionNameParam)
                                ? self.listCollectionNameParam(model)
                                : model.get(self.listCollectionNameParam);
                            self.onlyResult = {
                                id: id,
                                text: text
                            };
                            // Force the change event
                            self.handleChangeEvent();
                            self.renderContent();
                        } else if (self.initialCollection.state.totalRecords > self.minRows) {
                            self.listCollection.reset(self.initialCollection.models);
                            self.renderContent();
                        } else {
                            self.$el.html(
                                `<label>${self.label}</label><p class="text-warning">${self.noResultsMessage}</p`
                            );
                        }
                    },
                    error: function(collection, xhr) {
                        self.$el.html(`<div id=${self.name}></div>`);
                        self.renderAlert({
                            el: `#${self.name}`,
                            alertType: 'danger',
                            message: CC.utils.getAjaxErrorMessage(xhr, 'Failed to get client list')
                        });
                    }
                });
            } else {
                if (!self.checkApi) {
                    self.initialCollection = self.listCollection.clone();
                }
                self.renderContent();
            }
            return this;
        },

        renderContent: function() {
            const self = this;
            // $el can be undefined if view was closed between render and response
            if (!_.isUndefined(self.$el)) {
                self.$el.empty().append(
                    self.template({
                        name: self.elementName,
                        label: self.label,
                        defaultValue: self.defaultValue,
                        isMultiSelect: self.isMultiSelect,
                        needsSearch: self.needsSearch,
                        allowClear: self.allowClear
                    })
                );

                if (_.isUndefined(self.onlyResult)) {
                    self.setUpSelect();
                } else {
                    self.$el.hide();
                }
            }
        },

        setUpSelect: function() {
            const self = this;
            self.callbackParams = {};

            let selectOptions = {
                placeholder: self.placeholder,
                allowClear: self.allowClear,
                closeOnSelect: self.closeOnSelect,
                width: self.width,
                cache: self.useCache,
                quietMillis: self.quietMillis,
                minimumResultsForSearch: self.minimumResultsForSearch,
                tags: self.useTags,
                language: {
                    noResults: function() {
                        return self.noResultsMessage;
                    }
                },
                templateSelection: function(selection) {
                    if (self.isMultiSelect) {
                        const selected = ($(self.elementNameSelector).val() || []).length;
                        return $(`<span class="select2-selection__choice__count">${selected}</span>`);
                    } else {
                        if (!selection.id) {
                            return selection.text;
                        }
                    }
                    return selection.text;
                },
                theme: 'terrene',
                dropdownParent: self.$('.select')
            };

            if (!_.isUndefined(self.initialCollection)) {
                selectOptions = _.extend(selectOptions, {
                    placeholder: self.initialCollection.length > 0 ? self.placeholder : self.noResultsMessage,
                    data: self.formatGroupedSelections(
                        self.initialCollection.map(function(model) {
                            const id = _.isFunction(self.listCollectionIdParam)
                                ? self.listCollectionIdParam(model)
                                : model.get(self.listCollectionIdParam);
                            const text = _.isFunction(self.listCollectionNameParam)
                                ? self.listCollectionNameParam(model)
                                : model.get(self.listCollectionNameParam);
                            const grouping = _.isFunction(self.listCollectionGroupingParam)
                                ? self.listCollectionGroupingParam(model)
                                : model.get(self.listCollectionGroupingParam);
                            return {
                                id: `${id}`,
                                text: text,
                                grouping: grouping
                            };
                        })
                    ),
                    templateResult: self.formatSelectionFn,
                    // Search for name and id
                    matcher: function(params, data) {
                        if ($.trim(params.term) === '') {
                            return data;
                        }

                        //If there are no children (i.e. not a group), filter the selection and don't put it as a top-level selection (not a group header)
                        if (typeof data.children === 'undefined') {
                            if (
                                data.text.toUpperCase().indexOf(params.term.toUpperCase()) >= 0 ||
                                data.id == params.term
                            ) {
                                return data;
                            }
                        }

                        const filteredChildren = [];
                        _.each(data.children, function(child) {
                            if (
                                child.text.toUpperCase().indexOf(params.term.toUpperCase()) >= 0 ||
                                data.id == params.term
                            ) {
                                filteredChildren.push(child);
                            }
                        });

                        if (filteredChildren.length) {
                            const modifiedData = $.extend({}, data, true);
                            modifiedData.children = filteredChildren;
                            return modifiedData;
                        }

                        return null;
                    }
                });
            }

            if (self.useTags) {
                selectOptions.createTag = self.createTag;
            }

            if (_.isUndefined(self.listCollection)) {
                selectOptions = _.extend(selectOptions, {
                    minimumInputLength: self.minimumInputLength,
                    createSearchChoice: self.createSearchChoiceFn,
                    templateResult: self.formatSelectionFn,
                    ajax: {
                        url: function() {
                            return self.dataUrl;
                        },
                        dataType: 'json',
                        data: self.dataFn,
                        processResults: function(data, page) {
                            return {
                                results: self.processResultsFn(data)
                            };
                        }
                    }
                });
            } else {
                // Search by the listCollection if we don't have all the results
                if (
                    (!_.isUndefined(self.initialCollection) &&
                        !_.isUndefined(self.initialCollection.hasNextPage) &&
                        self.initialCollection.hasNextPage()) ||
                    _.isUndefined(self.initialCollection)
                ) {
                    selectOptions = _.extend(selectOptions, {
                        templateResult: self.formatSelectionFn,
                        ajax: {
                            data: function(params) {
                                return {
                                    term: params.term,
                                    page: params.page
                                };
                            },
                            transport: function(params, success, failure) {
                                self.callback = success;
                                self.callbackParams = params || {};

                                const page = params.data.page || 1;
                                if (_.isUndefined(params.data.term)) {
                                    _.each(self.autoCompleteQueryParams, function(param) {
                                        delete self.listCollection.queryParams[param];
                                    });
                                } else {
                                    _.each(self.autoCompleteQueryParams, function(param) {
                                        self.listCollection.queryParams[param] = params.data.term;
                                    });
                                }

                                if (self.xhr) {
                                    self.xhr.abort();
                                }
                                self.xhr = self.listCollection.getPage(page);
                            }
                        }
                    });
                }
            }

            this.$(self.elementNameSelector).select2(selectOptions);
            if (this.isMultiSelect && this.listCollection.length < MIN_ITEMS_FOR_SEARCH) {
                this.$(self.elementNameSelector).on('select2:opening select2:closing', function(event) {
                    const $searchfield = $(this)
                        .parent()
                        .find('.select2-search__field');
                    $searchfield.prop('disabled', true);
                });
            }

            this.$(this.elementNameSelector).on('select2:select', event => {
                this.$('.select2-container--terrene').addClass('select2-container--selected');
                this.handleChangeEvent(event);
            });
            this.$(this.elementNameSelector).on('select2:unselect', event => {
                if (
                    this.isMultiSelect &&
                    event.params.originalEvent &&
                    event.params.originalEvent.target.className === 'select2-selection__choice__remove'
                ) {
                    // Clear all selections
                    this.setValue(null);
                    this.handleChangeEvent(event);
                    // We need a full re-render so that the magnifying glass doesn't get removed
                    // from shifting library elements from a clear all. Also, we just cleared everything
                    // so a full render again is fine
                    this.render();
                    return;
                }

                this.handleChangeEvent(event);
            });
            this.delegateEvents();
            return this;
        },

        cleanUpSelect: function() {
            this.$(this.elementNameSelector).off('change');
            this.$(this.elementNameSelector).off('select2:opening');
            this.$(this.elementNameSelector).off('select2:closing');
            this.$(this.elementNameSelector).off('select2:select');
            this.$(this.elementNameSelector).off('select2:unselect');
        },

        handleChangeEvent: function(e) {
            this.changeHandler.apply(this, [e, this.getSelection(), this.getValue()]);
            this.trigger('change', this.getSelection(), this.getValue());
            if (this.isFilter) {
                this.filter();
            }
            if (!this.hasValue(this.getSelection())) {
                this.$('.select2-container--terrene').removeClass('select2-container--selected');
            }
        },

        processResults: function() {
            const self = this;

            if (!self.callback) {
                // The view has apparently gone away before this fired
                return;
            }
            const mappedResults = _.map(self.listCollection.models, function(model) {
                const id = _.isFunction(self.listCollectionIdParam)
                    ? self.listCollectionIdParam(model)
                    : model.get(self.listCollectionIdParam);
                const text = _.isFunction(self.listCollectionNameParam)
                    ? self.listCollectionNameParam(model)
                    : model.get(self.listCollectionNameParam);
                return {
                    id: id,
                    text: text
                };
            });
            // TODO: Find a better way to support non-pageable collections
            self.callbackParams.page = self.listCollection.state.currentPage;
            self.callback({
                results: mappedResults,
                pagination: {
                    more: self.listCollection.hasNextPage()
                }
            });
        },

        clearSelection: function() {
            this.$(this.elementNameSelector)
                .val(null)
                .trigger('change');
        },

        getSelection: function() {
            const selection = this.getFullSelection();
            if (this.hasOnlyOne()) {
                return selection.id;
            }
            const selections = _.map(selection, function(element) {
                return element.id;
            });
            if (selections.length === 1 && !this.isMultiSelect && this.forceCleanSelections) {
                return selections[0];
            }
            return _.compact(selections);
        },

        getFullSelection: function() {
            if (this.hasOnlyOne()) {
                return this.onlyResult;
            }
            const data = this.$(this.elementNameSelector).select2('data');
            return _.isUndefined(data) ? null : data;
        },

        setValue: function(selection) {
            this.$(this.elementNameSelector)
                .val(selection)
                .trigger('change');
        },

        disable: function() {
            this.disableElement(this.elementNameSelector);
        },

        enable: function() {
            this.enableElement(this.elementNameSelector);
        },

        // Return the selected model from the collection
        // Note: this may return undefined if used on a pageable collection and selected value
        // is not on the first page. It is not in the listCollection because that isn't being updated.
        getValue: function() {
            const self = this;
            if (this.hasOnlyOne()) {
                return new Backbone.Model(this.onlyResult);
            }
            const selection = self.getSelection();
            if (_.isEmpty(selection)) {
                return undefined;
            }

            if (_.isUndefined(self.listCollection)) {
                return selection;
            }

            return self.listCollection.get(selection);
        },

        getFilterValue: function() {
            return this.getSelection();
        },

        hasOnlyOne: function() {
            return !_.isUndefined(this.onlyResult);
        },

        // Adds and sets a new option. It checks for it being present already, else it adds it and triggers a change.
        addOption: function(model) {
            const self = this;
            self.listCollection.add(model);
            const modelId = model.get('id');
            if (this.$(this.elementNameSelector).find(`option[value='${modelId}']`).length) {
                this.$(this.elementNameSelector)
                    .val(modelId)
                    .trigger('change');
            } else {
                const text = _.isFunction(self.listCollectionNameParam)
                    ? self.listCollectionNameParam(model)
                    : model.get(self.listCollectionNameParam);
                const newOption = new Option(text, modelId, true, true);
                this.$(this.elementNameSelector)
                    .append(newOption)
                    .trigger('change');
            }
        },

        open: function() {
            const self = this;
            self.$(self.elementNameSelector).select2('open');
        },

        //Formats selections by group in the way that SearchSelect expects to get them
        formatGroupedSelections: function(selectionList) {
            const groupedSelections = _.groupBy(selectionList, function(item) {
                return item['grouping'];
            });

            let formattedGroups = [];

            for (const group in groupedSelections) {
                // This is actually checking for a property with the name "undefined" (as opposed to doing a typeof check)
                // It's a result of having selections with no group when the groupBy() is done.
                if (group === 'undefined') {
                    //Just add the ungrouped selections as top-level selections
                    formattedGroups = formattedGroups.concat(groupedSelections[group]);
                } else {
                    //Add the selections as children of the group
                    formattedGroups.push({
                        id: group,
                        text: group,
                        children: groupedSelections[group]
                    });
                }
            }

            return formattedGroups;
        }
    });

    // Ensure the events and defaults extend to all children
    SearchSelect.extend = function(child) {
        const view = BasicFilterView.extend.apply(this, arguments);
        view.prototype.events = _.extend({}, this.prototype.events, child.events);
        view.prototype.defaults = _.extend({}, this.prototype.defaults, child.defaults);
        return view;
    };

    return SearchSelect;
});
