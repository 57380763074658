define(function(require) {
    const BasicModel = require('models/app/BasicModel');
    const $ = require('jquery');
    const _ = require('underscore');
    const Backbone = require('backbone');
    const CC = require('CC');
    require('backbone.paginator');

    // Backbone Class Properties (http://backbonejs.org/#Model-extend)
    // For static proprties and functions. A custom extend override below
    // will copy them to all collections that extend BaseCollection.
    const classProperties = {
        // Sorting direction - use these instead of the numbers for clearer code.
        sortDir: {
            ASC: -1,
            DESC: 1
        }
    };
    const baseCollection = Backbone.PageableCollection.extend(
        {
            // Parameters for path portion of URL - included in URL by default.
            // Override in child class as needed.
            // Note: pathParams used specifically in the PATH of the URL, not the query string.
            //       They are treated separately from the Backbone Pageable queryParams below.
            defaultPathParams: {},

            // Override in child class to set default options.
            // Note: avoid setting defaultPathParams, defaultQueryParams here; that just gets confusing.
            defaultOptions: {
                usePageSizeSetting: true, // false skips the call to setInitialPageSize below
                pageable: true, // Operates as a pagable collection, includes paging params on requests
                customPageSize: undefined, // number of rows in the grid if you need something other than the standard
                maxPageSize: 999999, // maximum pageSize used when a collection is not pageable
                searchQueryParam: 'search' // Default search query param
            },

            // State values are applied on initialize, and then become read only.
            // Sets a different default pageSize here because the Backbone.PageableCollection one of 25
            // never matches our options.
            defaultState: {
                pageSize: 20
            },

            // Most common queryParams configuration.
            defaultQueryParams: {
                totalPages: null,
                totalRecords: null,
                currentPage: 'page_num',
                pageSize: 'page_count',
                sortKey: 'sort_attr',
                order: 'sort_order',
                directions: {
                    '-1': 'ASC',
                    '1': 'DESC'
                }
            },

            // Intentionally left blank. Override queryParams in a child class. It
            // will supersede the defaultQueryParams above.
            queryParams: {},

            model: BasicModel,

            // Set parseRecordsAttribute to a string
            // if you want the parseRecords to key off a particular attribute.
            parseRecordsAttribute: undefined,

            // This covers most common case. Sometimes we use 'numAvailable' instead.
            // If you need something else, override the parseState function entirely.
            parseStateAttribute: 'totalRecordsForCriteria',

            // This is used by DataTables to ensure that the Ajax returns from server-side
            // processing requests are drawn in sequence by DataTables
            drawCounter: 0,

            // If using a DataGrid, this map keys from column name to sort attribute
            sortMap: {},

            // If using a DataGrid, this map keys from query param to options for that filter
            queryParamMap: {},

            // If using a DataGrid, sets the default sort order and attribute for that column
            defaultSort: {
                // sortKey: 'id',
                // order: ASC/DESC
                // label: 'Audience Id'
            },

            constructor: function(models, options) {
                // Handle default params and options
                // need a clone of the class defaults to ensure they're not overwritten.
                // evaluate any deferred functions, allows us to set defaults dynamically.
                let defaultPathParams = _.extend({}, this.defaultPathParams);
                defaultPathParams = _.mapObject(defaultPathParams, function(dp) {
                    return _.isFunction(dp) ? dp() : dp;
                });

                options = options || {};
                this.pathParams = _.extend({}, defaultPathParams, this.pathParams || {}, options.pathParams || {});

                // this.defaultOptions - set here in this base class
                // this.subclassOptions - set on a class
                // this.options - set on collections that use Backbone.extend
                // options - sent in when the collection is created
                this.options = _.extend({}, this.defaultOptions, this.options, this.subclassOptions || {}, options);

                // We want to apply child class state, but if nothing is set, check if it's using the
                // Backbone.PageableCollection state instead. Check for that by looking for the signal,
                // which is a pageSize of 25 - we never want that, so use an empty object instead.
                const childState =
                    typeof this.state.pageSize === 'undefined' || this.state.pageSize !== 25 ? this.state : {};
                this.state = _.extend({}, this.defaultState, childState, this.options.state || {});

                if (!this.defaultSort?.sortKey) {
                    this.defaultSort = {
                        sortKey: this.state.sortKey,
                        order: this.state.order,
                        label: 'Default Sort'
                    };
                }

                this.queryParams = _.extend(
                    {},
                    Backbone.PageableCollection.prototype.queryParams,
                    this.defaultQueryParams, // from this base class
                    this.queryParams, // from child class
                    this.options.queryParams // typically from a view
                );

                // Check for any option 'params' being sent in. These are handled last, so will override
                // the pathParams and queryParams that were already applied.
                if (!_.isUndefined(this.options.params)) {
                    this.setParams(this.options.params);
                }

                Backbone.PageableCollection.apply(this, arguments);

                // state tracking
                this.loading = false;
                this.loaded = false;
            },

            initialize: function(models, options) {
                const self = this;
                if (self.options.pageable) {
                    if (self.options.usePageSizeSetting) {
                        self.setInitialPageSize(self.options.customPageSize);
                    }
                } else {
                    // pageSize here doesn't go out on the query params, but it does affect
                    // how many records may be included in the results.
                    // see: backbone-paginator getPage()
                    self.state.pageSize = self.options.maxPageSize;
                }

                // Update the mode based on pageable option unless it is explicitly passed in.
                // It is important to do this while initializing, otherwise BackbonePageable gets confused and has to re-fetch.
                self.mode = self.options.mode || (self.options.pageable ? 'server' : 'client');

                // Give this collection a unique id so the pageable sync override
                // will only cancel requests from this
                this._cId = _.uniqueId();
                Backbone.PageableCollection.prototype.initialize.apply(this, arguments);
                self.bind('sync', this.onSync, this);
            },

            // Override this to generate the base URL to which params will be applied.
            // Note: Backbone.Collection does NOT have a url() method defined out of the gates,
            //       even if the documentation suggests otherwise. (as of 1.3.3)
            urlRoot: function() {
                // this ensures we're starting with a string
                return '';
            },

            // Note: the url() doesn't include the query parameters because bg-p is adding them in during the fetch()
            url: function() {
                return this.fillInPlaceholders(this.urlRoot());
            },

            fillInPlaceholders: function(my_url) {
                // find the location of the 1st ?
                // go to ? or end of string
                let pos = my_url.indexOf('?');
                if (pos === -1) {
                    pos = my_url.length;
                }
                const sub_url = my_url.substr(0, pos);
                if (sub_url.indexOf('{') !== -1) {
                    // look for any {} placeholders
                    const pattern = /\{[a-zA-Z_$][0-9a-zA-Z_$]*\}/g;
                    const match = sub_url.match(pattern);

                    // grab the placeholder string out of it
                    if (match) {
                        for (let i = 0; i < match.length; i++) {
                            const placeholder = match[i];
                            const param_name = placeholder.substring(1, placeholder.length - 1);
                            // looking for replacements
                            if (typeof this.pathParams[param_name] !== 'undefined') {
                                // explicit params first
                                my_url = my_url.replace(placeholder, this.pathParams[param_name]);
                            } else if (typeof this.options[param_name] !== 'undefined') {
                                // collection options next
                                my_url = my_url.replace(placeholder, this.options[param_name]);
                                console.error(
                                    'ERROR: using a deprecated approach of a path parameter falling back to collection option.'
                                );
                            } else if (typeof this[param_name] !== 'undefined') {
                                // fall back to collection-level attributes
                                my_url = my_url.replace(placeholder, this[param_name]);
                                console.error(
                                    'ERROR: using a deprecated approach of a path parameter falling back to collection-level attribute.'
                                );
                            }
                        }
                    }
                }
                return my_url;
            },

            hasPathParamPlaceholder: function(param) {
                const my_base_url = this.urlRoot();
                return my_base_url.indexOf(`{${param}}`) !== -1;
            },

            setParam: function(param, value) {
                if (this.hasPathParamPlaceholder(param)) {
                    this.setPathParam(param, value);
                } else {
                    this.setQueryParam(param, value);
                }
                return this;
            },

            setParams: function(params) {
                for (const param in params) {
                    this.setParam(param, params[param]);
                }
                return this;
            },

            getParam: function(param) {
                let value;
                if (this.hasPathParamPlaceholder(param)) {
                    value = this.getPathParam(param);
                } else {
                    value = this.getQueryParam(param);
                }
                return value;
            },

            unsetParam: function(param) {
                if (this.hasPathParamPlaceholder(param)) {
                    this.unsetPathParam(param);
                } else {
                    this.unsetQueryParam(param);
                }
                return this;
            },

            unsetParams: function(params) {
                for (const param in params) {
                    this.unsetParam(param);
                }
                return this;
            },

            setPathParam: function(param, value) {
                this.pathParams[param] = value;
                return this;
            },

            setPathParams: function(pathParams) {
                this.pathParams = _.extend(this.pathParams, pathParams);
                return this;
            },

            unsetPathParam: function(param) {
                if (typeof this.pathParams[param] !== 'undefined') {
                    delete this.pathParams[param];
                }
                return this;
            },

            getPathParam: function(param) {
                let value;
                if (typeof this.pathParams[param] !== 'undefined') {
                    value = this.pathParams[param];
                }
                return value;
            },

            getPathParams: function() {
                return this.pathParams;
            },

            // update an existing query parameter, or add one if it does not exist
            setQueryParam: function(key, value) {
                this.queryParams[key] = value;
                this.trigger('QueryParam:Change', key, value);
                return this;
            },

            getQueryParam: function(key) {
                let value;
                if (typeof this.queryParams[key] !== 'undefined') {
                    value = this.queryParams[key];
                }
                return value;
            },

            setQueryParams: function(queryParams) {
                _.keys(queryParams).forEach(key => this.trigger('QueryParam:Change', key, queryParams[key]));
                this.queryParams = _.extend(this.queryParams, queryParams);
                return this;
            },

            getQueryParams: function() {
                return this.queryParams;
            },

            unsetQueryParam: function(key) {
                if (typeof this.queryParams[key] !== 'undefined') {
                    delete this.queryParams[key];
                    this.trigger('QueryParam:Change', key, undefined);
                }
                return this;
            },

            setSearchParam: function(value) {
                if (value) {
                    this.setQueryParam(this.options.searchQueryParam, value);
                } else {
                    this.unsetQueryParam(this.options.searchQueryParam);
                }
            },

            getSearchParam: function() {
                return this.getQueryParam(this.options.searchQueryParam);
            },

            // Note: no setter for pageable because it really needs to be set in initialize
            isPageable: function() {
                return this.options.pageable;
            },

            fetch: function(options) {
                const self = this;
                // By default use the traditional params and not the []s for query params that occur several times
                const defaultOptions = {
                    traditional: true
                };
                const fetchOptions = _.extend({}, defaultOptions, options);
                self.loading = true;
                self.loaded = false;
                self.incrementDrawCounter();
                self.trigger('fetch');
                const request = Backbone.PageableCollection.prototype.fetch.call(self, fetchOptions);
                return request;
            },

            incrementDrawCounter: function() {
                return this.drawCounter++;
            },

            onSync: function() {
                const self = this;
                self.loading = false;
                self.loaded = true;
            },

            parseRecords: function(response) {
                const self = this;
                let records = response;
                if (response && !_.isUndefined(response[self.parseRecordsAttribute])) {
                    records = response[self.parseRecordsAttribute];
                }
                return records;
            },

            parseState: function(response) {
                const self = this;
                const state = {};
                if (response && !_.isUndefined(response[self.parseStateAttribute])) {
                    state.totalRecords = parseInt(response[self.parseStateAttribute], 10) || 0;
                }
                return state;
            },

            isLoading: function() {
                return this.loading;
            },

            isLoaded: function() {
                return this.loaded;
            },

            setInitialPageSize: function(size) {
                let newPageSize;
                // look for passed-in value first
                if (typeof size !== 'undefined') {
                    newPageSize = size;
                } else {
                    const defaultLength = CC.utils.getUserSetting('defaultListLength');
                    if (defaultLength) {
                        newPageSize = defaultLength.get('value');
                    }
                }
                if (typeof newPageSize !== 'undefined' && newPageSize !== '') {
                    const pageSize = parseInt(newPageSize, 10);
                    if (pageSize > 0) {
                        this.state.pageSize = pageSize;
                    }
                }
            },

            clone: function() {
                const cloneOptions = this.options || {};
                return new this.constructor(
                    this.models,
                    _.extend(
                        {
                            model: this.model,
                            comparator: this.comparator,
                            state: this.state
                        },
                        cloneOptions
                    )
                );
            },

            // Re-implementation of the PageableCollection setPageSize that
            // allows the value to be set without actually fetching the collection.
            // We need it this way because we often want to set a size during a view initialize,
            // but the fetch happens later from e.g. a grid being rendered.
            //
            // To NOT fetch, set options.deferFetch = true;
            setPageSize: function(pageSize, options) {
                pageSize = this.finiteInt(pageSize, 'pageSize');

                options = options || { first: false };
                let promise;
                let state = this.state;
                const totalPages = Math.ceil(state.totalRecords / pageSize);
                const currentPage = totalPages
                    ? Math.max(state.firstPage, Math.floor((totalPages * state.currentPage) / state.totalPages))
                    : state.firstPage;

                state = this.state = this._checkState(
                    _.extend({}, state, {
                        pageSize: pageSize,
                        currentPage: options.first ? state.firstPage : currentPage,
                        totalPages: totalPages
                    })
                );

                if (!_.isUndefined(options.deferFetch) && options.deferFetch) {
                    // create a fulfilled Promise so that things like .done() will work.
                    promise = $.Deferred();
                    promise.resolve(100); // was Promise.resolve(100);
                } else {
                    options.reset = true;
                    promise = this.getPage(this.state.currentPage, _.omit(options, ['first']));
                }
                return promise;
            },

            getPageSize: function() {
                return this.state.pageSize;
            },

            // Copied over from PageableCollection to maintain the same functionality
            finiteInt: function(val, name) {
                if (!_.isNumber(val) || _.isNaN(val) || !_.isFinite(val) || ~~val !== val) {
                    throw new TypeError(`\`${name}\` must be a finite integer`);
                }
                return val;
            },

            // Server-side acts exactly the same as normal toJSON.
            // Client-side will map out the entire collection. This makes it so saving a model who
            // has an attribute which is a client-side Base Collection will correctly save the entire collection
            // instead of just the first page
            toJSON: function(options = {}) {
                const self = this;
                if (self.mode === 'server') {
                    return self.map(function(model) {
                        if (options.includeCid) {
                            return _.extend({}, model.toJSON(options), {
                                cid: model.cid
                            });
                        } else {
                            return model.toJSON(options);
                        }
                    });
                } else {
                    return self.fullCollection.map(function(model) {
                        if (options.includeCid) {
                            return _.extend({}, model.toJSON(options), {
                                cid: model.cid
                            });
                        } else {
                            return model.toJSON(options);
                        }
                    });
                }
            },

            // Data Tables
            getOrder: function(columns) {
                const colIndex = columns.findIndex(
                    column => column.name?.toLowerCase() == this.state.sortKey?.toLowerCase()
                );
                if (colIndex !== -1) {
                    const order = this.state.order === classProperties.sortDir.DESC ? 'desc' : 'asc';
                    return [[colIndex, order]];
                }

                // The sort key refers to a column that is not visible so use the sort order
                // of the incoming data set instead of apply out own
                return [];
            },

            toDataTables: function() {
                return {
                    data: this.toJSON({
                        includeCid: true
                    }),
                    recordsTotal: this.state.totalRecords || 0,
                    recordsFiltered: this.state.totalRecords || 0,
                    draw: this.incrementDrawCounter()
                };
            },

            /**
             * Set Sorting
             * @param {String} sortKey
             * @param {Integer} order BaseCollection.sortDir.ASC or BaseCollection.sortDir.DESC
             * @param {Object} options
             * @returns results of the collection sort operation
             */
            setSorting: function(sortKey, order, options) {
                const resolvedKey = _.isUndefined(this.sortMap[sortKey]) ? sortKey : this.sortMap[sortKey];
                return Backbone.PageableCollection.prototype.setSorting.apply(this, [resolvedKey, order, options]);
            },

            /**
             * Set the sort values back to their defaults, if set
             * @returns
             */
            resetSorting: function() {
                if (_.isEmpty(this.defaultSort)) {
                    return;
                }
                return this.setSorting(this.defaultSort.sortKey, this.defaultSort.order);
            },

            getFormattedParamValue: function(paramName, value) {
                const details = this.queryParamMap[paramName];

                if (details) {
                    const idParam = details.idParam || 'id';
                    const option = details.options.find(option => {
                        if (option.attributes) {
                            return option.get(idParam) == value;
                        }
                        return option[idParam] == value;
                    });
                    if (option) {
                        option.unsetOption = details.unsetOption;
                        return option;
                    }
                }
                return undefined;
            },

            getOptionsForQueryParam: function(paramName) {
                return this.queryParamMap[paramName].options.filter(option => {
                    const filledInOption = Object.assign(
                        {
                            isVisible: () => {
                                return true;
                            }
                        },
                        option
                    );
                    return filledInOption.isVisible();
                });
            },

            /**
             * Configures a filter option for the collection
             * @param {Object} params - Configuration parameters
             * @param {String} params.key - The filter key to configure
             * @param {Backbone.Collection} params.optionsCollection - Collection of available filter options
             * @param {String} [params.unsetValue=CLEAR] - Value to use when filter is unset
             * @param {String} [params.idParam=id] - Name of the ID field to use for options
             * @param {Function} [params.summaryFormatter=null] - Function to format the summary of each option
             * @returns {baseCollection} Returns this for chaining
             */
            configureFilterOption({
                key,
                optionsCollection,
                unsetValue = 'CLEAR',
                idParam = undefined,
                summaryFormatter = null
            }) {
                if (!key) {
                    throw new Error('Filter key is required');
                }

                if (!optionsCollection) {
                    throw new Error('Filter options are required');
                }

                // Apply custom summary formatting if provided
                if (summaryFormatter) {
                    optionsCollection.each(model => {
                        model.set('summary', summaryFormatter(model));
                    });
                }

                const normalizedUnsetValue = unsetValue?.toString() ?? '';
                this.queryParamMap[key] = {
                    unsetOption: normalizedUnsetValue,
                    options: optionsCollection || [],
                    idParam: idParam
                };

                return this;
            },

            getAllPages: function(bulkPageSize = 2000) {
                const promise = $.Deferred();
                if (this.mode === 'client') {
                    return promise.resolve(this.fullCollection);
                } else {
                    const queryParams = _.omit(this.queryParams, [
                        'currentPage',
                        'pageSize',
                        'totalPages',
                        'totalRecords',
                        'sortKey',
                        'order',
                        'directions'
                    ]);
                    const pathParams = _.isUndefined(this.pathParams) ? {} : this.pathParams;
                    const otherOptions = this.options;
                    const exportCollection = new this.constructor(
                        [],
                        _.extend({}, otherOptions, {
                            queryParams: queryParams,
                            pathParams: pathParams
                        })
                    );

                    exportCollection.state.pageSize = bulkPageSize;
                    exportCollection.state.sortKey = this.state.sortKey;
                    exportCollection.state.order = this.state.order;

                    if (this.state.currentPage === 1 && !this.hasNextPage()) {
                        return promise.resolve(this);
                    }

                    this.listenTo(exportCollection, 'sync', () => {
                        this.trigger('AllPages', {
                            currentPage: exportCollection.state.currentPage,
                            fetched: exportCollection.length,
                            totalRecords: exportCollection.state.totalRecords
                        });
                    });
                    this.listenTo(exportCollection, 'error', (collection, xhr) => {
                        this.trigger('AllPages:Error', collection, xhr);
                    });
                    exportCollection.getFirstPage({
                        success: () => {
                            this._finishGet(exportCollection, promise);
                        },
                        error: function(collection, xhr) {
                            promise.reject(CC.utils.getAjaxErrorMessage(xhr, 'Error getting page'));
                        }
                    });
                    return promise;
                }
            },
            _finishGet: function(exportCollection, promise) {
                if (exportCollection.hasNextPage()) {
                    exportCollection.getNextPage({
                        remove: false,
                        success: () => {
                            this._finishGet(exportCollection, promise);
                        },
                        error: function(collection, xhr) {
                            promise.reject(CC.utils.getAjaxErrorMessage(xhr, 'Error getting page'));
                        }
                    });
                } else {
                    promise.resolve(exportCollection);
                }
            }
        },
        classProperties
    );

    /**
     * A custom override to extend that will copy class properties to
     * all collections that extend from this one
     * @param  {Object} child                The child object
     * @param  {Object} childClassProperties Optional class properties that will override this class's version
     */
    baseCollection.extend = function(child, childClassProperties) {
        const collection = Backbone.PageableCollection.extend.apply(this, [
            child,
            _.extend({}, classProperties, childClassProperties)
        ]);
        return collection;
    };
    return baseCollection;
});
