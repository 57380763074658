define(function(require) {
    const $ = require('jquery');

    const Backbone = require('backbone');
    const _ = require('underscore');
    const queryString = require('query-string');
    const simplur = require('simplur');
    const Logger = require('Logger').default;
    require('backboneValidation');
    require('backbone.paginator');
    require('backboneCacheBust');

    ////////////////////////////////////////////////////////////////////////////////////
    // CC namespaces
    // @property DataStore {Object}
    ////////////////////////////////////////////////////////////////////////////////////
    const CC = {
        DataStore: {
            registry: new Backbone.Model(),
            /**
             *
             * @param {String|Object} name
             * @param {any} value
             * @param {Object} options
             */
            register: function(name, value, options) {
                if (_.isObject(name)) {
                    CC.DataStore.registry.set(name);
                } else {
                    CC.DataStore.registry.set(name, value);
                }
            },

            /**
             * Should only be called as part where it can be easily overridden (eg. view defaults)
             * @param {String} name
             * @param {any} defaultValue
             * @returns
             */
            get: function(name, defaultValue) {
                return CC.DataStore.registry.get(name) || defaultValue;
            },

            clear: function() {
                CC.DataStore.registry.clear();
            }
        }
    };

    // Copy configs over from global set in Flight header
    if (typeof tomaConfigs !== 'undefined') {
        // eslint-disable-next-line no-undef
        CC.configData = tomaConfigs;
    } else {
        // Testing
        CC.configData = require('test/tomaTestConfigs.json');
    }

    // global events handler
    CC.Events = _.extend(
        {
            PAGE_CLEAR_ALERT: 'Page:ClearAlert'
        },
        Backbone.Events
    );
    CC.tomatomaUrl = CC.configData.CCTomaTomaUrl; //'https://tomatoma.dev.lotame.com'
    CC.apiUrl = CC.configData.CCAPIUrl + CC.configData.CCAPIVersion; //'https://api.dev.lotame.com/1';
    CC.apiHealthCheckPixel = CC.configData.ApiPixel;

    CC.SessionData = {};

    CC.userIsImpersonating = false;
    CC.pageScheme = 'page-scheme-veteris';

    // retrieves parent container url from code inside iframe
    CC.getParentUrl = function() {
        return `${document.referrer.split('.com/')[0]}.com`;
    };

    $(document).ajaxError(function(evt, jqxhr, settings, thrownError) {
        // Record all errors except aborts from filter changes
        if (thrownError !== 'abort') {
            const action = 'AjaxError';
            try {
                // const category = 'GLOBAL';
                // const label = thrownError;
                // TODO: Potentially replace with Pendo
            } catch (ex) {
                console.error('Failed to track Error Event');
            }
            CC.utils.recordError('AjaxError', thrownError, jqxhr);
        }

        $(document).ajaxStart(() => {
            // Clear alerts on any network call start
            CC.Events.trigger(CC.Events.PAGE_CLEAR_ALERT);
        });
    });

    ////////////////////////////////////////////////////////////////////////////////////
    // Impersonation
    ////////////////////////////////////////////////////////////////////////////////////

    //starts impersonation in the api
    CC.getImpersonating = function(callback) {
        if (!CC.configData.BypassCAS) {
            $.ajax({
                url: `${CC.apiUrl}/users/impersonateUser`,
                type: 'GET',
                processData: false,
                contentType: 'application/json',
                crossDomain: true,
                xhrFields: { withCredentials: true },
                success: function(response) {
                    callback(response);
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    CC.utils.recordError('Get Impersonation', textStatus, jqXHR);
                }
            });
        }
    };

    CC.startImpersonating = function(data) {
        CC.getImpersonating(function(response) {
            if (!response) {
                $.ajax({
                    url: `${CC.apiUrl}/users/impersonateUser`,
                    type: 'PUT',
                    data: JSON.stringify(data),
                    processData: false,
                    contentType: 'application/json',
                    crossDomain: true,
                    xhrFields: { withCredentials: true },
                    success: function() {
                        CC.setImpersonating(true);
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        CC.utils.recordError('Start Impersonation', textStatus, jqXHR);
                    }
                });
            } else {
                CC.setImpersonating(true);
            }
        });
    };

    //stops impersonation in the api
    CC.stopImpersonating = function() {
        CC.clearSession();
        CC.getImpersonating(function(response) {
            if (response) {
                $.ajax({
                    url: `${CC.apiUrl}/users/impersonateUser`,
                    type: 'DELETE',
                    contentType: 'application/json',
                    crossDomain: true,
                    xhrFields: { withCredentials: true },
                    success: function() {
                        CC.setImpersonating(false);
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        CC.utils.recordError('End Impersonation', textStatus, jqXHR);
                    }
                });
            } else {
                CC.setImpersonating(false);
                CC.killImpersonation = false;
            }
        });
    };

    CC.setImpersonating = function(impersonating) {
        CC.userIsImpersonating = impersonating;
        CC.Events.trigger('impersonation:changed');
        return CC.userIsImpersonating;
    };

    //start impersonation if portal_src has imp parameter (added by vaudeville when impersonating)
    if (document.location.href.split('portal_src=')[1]) {
        if (document.location.href.split('portal_src=')[1].split('imp=')[1]) {
            const username = document.location.href
                .split('portal_src=')[1]
                .split('imp=')[1]
                .split('&')[0];
            CC.impersonatingUser = { username: username };
            CC.impersonationPossible = true;
            CC.killImpersonation = false;
        } else if (document.location.href.split('portal_src=')[1].split('killImp=')[1]) {
            CC.impersonationPossible = true;
            CC.killImpersonation = true;
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////
    // Base Objects
    ////////////////////////////////////////////////////////////////////////////////////

    // Backbone.Validation custom validators and patterns
    _.extend(Backbone.Validation.validators, {
        isType: function(value, attr, customValue, model) {
            if (typeof value !== customValue) {
                return `Value must be of type {${customValue}}`;
            }
        },
        instanceOf: function(value, attr, customValue, model) {
            if (!(value instanceof customValue)) {
                return `Value must be an instance of ${customValue.name}`;
            }
        },
        greaterThan: function(value, attr, customValue, model) {
            if (!this._compare('>', value, attr, customValue, model)) {
                return `Value must be greater than ${customValue.toString()}`;
            }
        },
        greaterThanOrEqual: function(value, attr, customValue, model) {
            if (!this._compare('>=', value, attr, customValue, model)) {
                return `value must be greater than or equal to ${customValue.toString()}`;
            }
        },
        lessThan: function(value, attr, customValue, model) {
            if (!this._compare('<', value, attr, customValue, model)) {
                return `Value must be less than ${customValue.toString()}`;
            }
        },
        lessThanOrEqual: function(value, attr, customValue, model) {
            if (!this._compare('<=', value, attr, customValue, model)) {
                return `Value must be less than or equal to ${customValue.toString()}`;
            }
        },
        _compare: function(op, value, attr, customValue, model) {
            let compareTo = customValue.value || customValue;

            // if the custom value is an attribute, return the value of that attribute
            if (_.isString(compareTo) && model.has(compareTo)) {
                compareTo = model.get(compareTo);
            }

            // run conversion against both values
            if (customValue.convert && _.isFunction(customValue.convert)) {
                value = customValue.convert.call(model, value);
                compareTo = customValue.convert.call(model, compareTo);
            }

            switch (op) {
                case '<':
                    return value < compareTo;
                case '>':
                    return value > compareTo;
                case '<=':
                    return value <= compareTo;
                case '>=':
                    return value >= compareTo;
                default:
                    return false;
            }
        }
    });

    _.extend(Backbone.Validation.patterns, {
        patterns: {}
    });

    // Maybe temporary - just some utility functions to serve as validation helpers as we struggle with Backbone Validation.
    // In some cases, like dealing with nested Backbone objects, we have to roll our own instead of using e.g. "model.attributes.attribute_name" notation
    CC.validators = {
        isPositiveNumber: function(n) {
            return typeof n != 'undefined' && (n != null) & (parseInt(n, 10) > 0);
        },

        isValidPrice: function(price, allowNull) {
            let isValid = false;
            if (typeof price != 'undefined') {
                const pattern = /^\$?\d*\.?\d{0,2}$/;
                isValid = price.match(pattern) != null;
            }
            if (isValid && typeof allowNull != 'undefined' && !allowNull) {
                isValid = price.length > 0 && parseFloat(price.replace(/[\$,]/g, '')) > 0;
            }
            return isValid;
        }
    };

    ////////////////////////////////////////////////////////////////////////////////////
    // Utility code / Backwards Compatibility
    ////////////////////////////////////////////////////////////////////////////////////

    // utility to capitalize 1st letter of string. This could be relocated if we get a lot of helper functions
    String.prototype.capitalize = function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    };
    String.prototype.toTitleCase = function() {
        return this.replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };

    // Putting useful utility code into a namespace
    CC.utils = {};

    // Returns true for empty or whitespace-only strings, null, or undefined
    CC.utils.isEmpty = function(str) {
        const isEmpty = (typeof str === 'string' && !str.trim()) || typeof str === 'undefined' || str === null;
        return isEmpty;
    };

    CC.utils.uniqid = function() {
        const newDate = new Date();
        return newDate.getTime() + Math.round(Math.random() * 100);
    };

    CC.utils.queryToObject = function() {
        const parsed = queryString.parse(location.search, {
            arrayFormat: 'bracket'
        });

        return parsed;
    };

    /**
     * Convert a number to friendly number to read
     * @param  {Number} number            The source number
     * @param  {Number} decPlaces         The number of decimal places
     * @param  {Boolean} precision        Whether or not to round to an imprecise number (Defaults to true)
     * @param  {Number} roundTo          Rounding level
     * @param  {String} abbreviation_type 'tiny' OR 'standard' (default)
     * @return {String}                   The friendly formatted number
     */
    CC.utils.friendly_number = function(number, decPlaces, precision, roundTo, abbreviationType) {
        if (typeof number == 'string') {
            number = parseInt(number, 10);
        }

        // Trim off decimal places first
        if (typeof number == 'number') {
            number = +number.toFixed(decPlaces || 0);
        }

        let isNegative = false;
        if (number < 0) {
            isNegative = true;
            number = Math.abs(number);
        }

        roundTo = parseInt(roundTo, 10) || 1000;
        if (number > 1000000) {
            decPlaces = 1;
        } else if (!decPlaces) {
            decPlaces = 0;
        }
        const digits = decPlaces;
        decPlaces = Math.pow(10, decPlaces);

        let abbreviationArray;
        switch (abbreviationType) {
            case 'tiny':
                abbreviationArray = ['K', 'M', 'B', 'T'];
                break;

            case 'standard':
            default:
                abbreviationArray = [' thousand', ' million', ' billion', ' trillion'];
        }

        for (let i = abbreviationArray.length - 1; i >= 0; i--) {
            const size = Math.pow(10, (i + 1) * 3);
            if (size <= number) {
                number = Math.round((number * decPlaces) / size) / decPlaces;
                if (typeof digits === 'number' && digits > 0 && digits < 20) {
                    number = number.toFixed(digits);
                }
                number += abbreviationArray[i];
                break;
            }
            if (!precision && number < roundTo) {
                if (number > 0) {
                    number = '<';
                    number += CC.utils.friendly_number(roundTo, 0, true, null, abbreviationType);
                }
            }
        }

        if (isNegative) {
            // Figure out how to put the negative symbol back in
            if (_.isString(number)) {
                if (number.indexOf('<') !== -1) {
                    return number;
                }
                return `-${number}`;
            }
            return -1 * number;
        }

        return number;
    };

    // Suitable for both Active Uniques and Panorama IDs
    CC.utils.getFormattedIdsString = function(ids, canBuy) {
        let idsText;
        if (!(_.isNull(ids) || _.isUndefined(ids) || ((_.isArray(ids) || _.isString(ids)) && _.isEmpty(ids)))) {
            idsText = (0 < ids && ids < 1000) ? '<1K' : CC.utils.addCommas(ids);
        } else if (canBuy) {
            idsText = '0';
        } else {
            idsText = 'N/A';
        }
        return idsText;
    };

    CC.utils.getUserSetting = function(key) {
        if (_.isEmpty(CC.SessionData)) {
            new Logger('CC').trace('CC: Session Data is being accessed before it has become available');
        }
        return CC.SessionData.userSettingsV2?.getByKey(key);
    };

    CC.utils.getUserSessionInfo = function(field) {
        let sessionValue;
        if (_.isEmpty(CC.SessionData)) {
            new Logger('CC').trace('CC: Session Data is being accessed before it has become available');
        }
        if (!_.isUndefined(CC.SessionData.userInfo)) {
            sessionValue = CC.SessionData.userInfo.get(field);
        }
        return sessionValue;
    };

    CC.utils.getUserMemberClientId = function() {
        const memberClientId = CC.utils.getUserSessionInfo('memberClientId');
        return memberClientId;
    };

    // Gets the role object for the client to which the user is assigned.
    CC.utils.getUserMemberClientRole = function() {
        let role = undefined;
        const memberClientId = CC.utils.getUserMemberClientId();
        const clientRoleSet = CC.SessionData.userInfo.get('clientRoleSet');
        if (!_.isUndefined(clientRoleSet)) {
            const roleObj = _.find(clientRoleSet, function(r) {
                return parseInt(r.client.id, 10) === memberClientId;
            });
            role = roleObj.role;
        }
        return role;
    };

    //
    // Account Info - Stored in SessionStorage by member client id in order to work across
    // impersonating across different clients
    //

    // Get the client id stored in session storage falling back to the user's member client id
    CC.utils.getCurrentClientId = function() {
        const storedClientId = localStorage.getItem(`Account_${CC.utils.getUserMemberClientId()}_id`);
        if (!_.isEmpty(storedClientId)) {
            return storedClientId;
        }
        return CC.utils.getUserMemberClientId();
    };

    // Get the client name stored in session storage falling back to the user's member client name
    CC.utils.getCurrentClientName = function() {
        const storedName = localStorage.getItem(`Account_${CC.utils.getUserMemberClientId()}_name`);
        const storedId = localStorage.getItem(`Account_${CC.utils.getUserMemberClientId()}_id`);
        if (storedName !== null && !_.isEmpty(storedId)) {
            return storedName;
        }

        if (CC.utils.getUserInfo()) {
            return CC.utils.getUserInfo().get('memberOrg');
        }
        return '';
    };

    // Store the current client info in session storage
    CC.utils.setCurrentClientInfo = function(client) {
        if (_.isUndefined(CC.utils.getUserMemberClientId()) || _.isNull(CC.utils.getUserMemberClientId())) {
            return 'No member client id';
        }

        if (_.isEmpty(client) || _.isEmpty(client.get('name') || _.isEmpty(client.get('id')))) {
            return 'Invalid client';
        }

        CC.utils.storeItem(`Account_${CC.utils.getUserMemberClientId()}`, JSON.stringify(client));
        CC.utils.storeItem(`Account_${CC.utils.getUserMemberClientId()}_name`, client.get('name'));
        CC.utils.storeItem(`Account_${CC.utils.getUserMemberClientId()}_id`, client.get('id'), {
            storeHistory: true,
            maxDays: 365
        });

        return undefined;
    };

    CC.utils.getCurrentClientHistory = function() {
        const history = JSON.parse(
            localStorage.getItem(`Account_${CC.utils.getUserMemberClientId()}_id_history`) || '[]'
        );
        return _.chain(history)
            .groupBy('value')
            .map(items => _.max(items, item => new Date(item.timestamp)))
            .value();
    };

    CC.utils.storeItem = function(key, value, options = {}) {
        const { maxEntries = 100, maxDays = 30, maxSizeKB = 50, storeHistory = false } = options;

        if (storeHistory) {
            let history = JSON.parse(localStorage.getItem(`${key}_history`) || '[]');
            const now = new Date();

            // Remove entries older than maxDays
            history = history.filter(
                entry => new Date(entry.timestamp) > new Date(now - maxDays * 24 * 60 * 60 * 1000)
            );

            // Add new entry
            history.push({
                value: value,
                timestamp: now.toISOString()
            });

            // Limit number of entries
            if (history.length > maxEntries) {
                history.splice(0, history.length - maxEntries);
            }

            // Check size and remove oldest entries until under limit
            while (JSON.stringify(history).length > maxSizeKB * 1024) {
                history.shift();
            }

            localStorage.setItem(`${key}_history`, JSON.stringify(history));
        }
        localStorage.setItem(`${key}`, value);
        return history;
    };

    CC.utils.getCurrentClientInfo = function() {
        if (_.isUndefined(CC.SessionData)) {
            new Logger('CC').warn('CC: Session Data is not set up yet');
        }
        return CC.SessionData.currentClient;
    };

    CC.utils.getCurrentClientFlags = function() {
        if (_.isUndefined(CC.SessionData)) {
            new Logger('CC').warn('CC: Session Data is not set up yet');
        }
        return CC.SessionData.clientFlags;
    };

    //
    // End Account Info
    //

    // Utility to store the whitelabel key, if found, on a login attempt.
    // We will look for it on the auth screen for branding purposes.
    CC.utils.storeWhiteLabelKey = function(value) {
        if (value !== 'admin' && value !== null) {
            CC.utils.writeCookie('white_label_key', value);
        }
    };

    // Will also remove a cookie if the value is undefined / empty string
    CC.utils.writeCookie = function(key, value, domain, maxAgeSeconds) {
        if (_.isUndefined(domain)) {
            // This will allow local to work, as long as CCEnv is set to 'dev'
            const env = CC.getConfigSetting('CCEnv');
            const subdomain = env === 'production' ? '' : `.${env}`;
            domain = `${subdomain}.lotame.com`;
        }
        let expiresStr = '';
        if (_.isUndefined(value)) {
            value = '';
            expiresStr = ' expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        } else if (!_.isUndefined(maxAgeSeconds)) {
            const expirationDate = new Date(Date.now() + maxAgeSeconds * 1000).toUTCString();
            expiresStr = ` expires=${expirationDate}`;
        }
        const cookieString = `${key}=${value}; Domain=${domain}; path=/; SameSite=Strict;${expiresStr}`;

        // set the cookie
        document.cookie = cookieString;
    };

    CC.utils.readCookie = function(key) {
        let cookieValue = null;
        const cookieString = `${document.cookie}`;
        if (cookieString.length !== 0) {
            const cookieMatch = cookieString.match(`${key}=([^;]*)`);
            if (typeof cookieMatch !== 'undefined' && cookieMatch !== null) {
                cookieValue = cookieMatch[1];
            }
        }
        return cookieValue;
    };

    CC.utils.getBrandLogo = function(whiteLabelKey) {
        return whiteLabelKey
            ? `${CC.getConfigSetting('imageCdnUrl')}/images/branded/${whiteLabelKey}/${whiteLabelKey}_navbar.png`
            : CC.getConfigSetting('lotameLogo');
    };

    CC.utils.getBrandLoginLogo = function(whiteLabelKey) {
        return whiteLabelKey
            ? `${CC.getConfigSetting('imageCdnUrl')}/images/branded/${whiteLabelKey}/${whiteLabelKey}_login.png`
            : CC.getConfigSetting('loginLogo');
    };

    CC.utils.getBaseClientLogoPath = function() {
        return `${CC.getConfigSetting('imageCdnUrl')}/images/client/`;
    };

    CC.utils.getUserInfo = function() {
        return CC.SessionData.userInfo;
    };

    // Adds commas to a number, ex: 1234567.89 becomes "1,234,567.89"
    // Note: this assumes a US format, if you want something else feel free to alter.
    CC.utils.addCommas = function(nStr) {
        nStr += '';
        const x = nStr.split('.');
        let x1 = x[0];
        const x2 = x.length > 1 ? `.${x[1]}` : '';
        const rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        return x1 + x2;
    };

    /**
     * This might be refactored into a CC.Model class... very common need to
     *     attach a list of key -> value parameters to an existing URL. We also
     *     use that jquery.query lib in places, but it really sucks - MBM
     * @param {String} url
     * @param {Array} queryParams
     * @param {Boolean} arraysAsCommaSeparated true to have  an array of parameter values
     *     added like "&key=value1,value2" (which is necessary for UI URL parameter passing)
     *     as opposed to the normal "&key=value1&key=value2" which is how the api takes
     *     in multivalue parameters
     * @returns {String}
     */
    CC.utils.addQueryParams = function(url, queryParams, arraysAsCommaSeparated) {
        let isFirst = true;
        _.each(queryParams, function(pValue, pKey) {
            let separator = isFirst && url.indexOf('?') == -1 ? '?' : '&';
            if (_.isArray(pValue)) {
                const arrLen = pValue.length;
                separator = isFirst ? '?' : '&';
                if (arraysAsCommaSeparated) {
                    url = `${url + separator + pKey}=${encodeURIComponent(pValue.toString())}`;
                    isFirst = false;
                } else {
                    for (let x = 0; x < arrLen; x++) {
                        separator = isFirst ? '?' : '&';
                        url = `${url + separator + pKey}=${encodeURIComponent(pValue[x])}`;
                        isFirst = false;
                    }
                }
            } else {
                url = `${url + separator + pKey}=${encodeURIComponent(pValue)}`;
                isFirst = false;
            }
        });
        return url;
    };

    // TODO - replace with moment.js usage
    // Utility to get the name of a month give the index. Used in Reports.
    // Dropped in here as a refactor, but move this into a lib or deprecate
    CC.utils.getMonthName = function(monthIndex) {
        let name = '';
        const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        if (!isNaN(parseInt(monthIndex, 10)) && monthIndex >= 0 && monthIndex <= 11) {
            name = monthNames[monthIndex];
        }
        return name;
    };

    CC.utils.getFormattedDate = function(dateObject) {
        let formattedDate = '';
        if (typeof dateObject === 'object') {
            formattedDate = `${CC.utils.getMonthName(
                dateObject.getMonth()
            )} ${dateObject.getDate()}, ${dateObject.getFullYear()}`;
        }
        return formattedDate;
    };

    // Reports helper function that is handy across all reports.
    // CC is probably not the right place for this - need a pattern to define helper libs
    CC.utils.getReportDates = function(dateRange, referenceDate) {
        let reportDates = '';
        let firstDay;
        let lastDay;

        switch (dateRange) {
            case 'YESTERDAY':
                firstDay = new Date();
                firstDay.setDate(firstDay.getDate() - 1);
                break;

            case 'LAST_30_DAYS':
                firstDay = new Date();
                firstDay = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() - 30);
                lastDay = new Date();
                break;

            case 'LAST_MONTH': {
                const todayDate = new Date();
                firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1);
                lastDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), 0);
                break;
            }
            case 'MONTH_TO_DATE':
                firstDay = new Date();
                firstDay = new Date(firstDay.getFullYear(), firstDay.getMonth(), 1);
                lastDay = new Date();
                break;

            case 'FULL_MONTH': {
                const year = referenceDate.substring(0, 4);
                const month = referenceDate.substring(4, 6);
                firstDay = new Date(year, month - 1, 1);
                lastDay = new Date(year, month, 0);
                break;
            }
        }
        if (typeof firstDay === 'object') {
            reportDates += CC.utils.getFormattedDate(firstDay);
        }
        if (typeof lastDay === 'object') {
            reportDates += ` - ${CC.utils.getFormattedDate(lastDay)}`;
        }
        return reportDates;
    };

    CC.utils.getAjaxErrorMessage = function(xhr, defaultMessage, options) {
        let message = defaultMessage || '';
        const opts = _.extend(
            {
                showDescription: false
            },
            options
        );

        if (typeof xhr !== 'undefined') {
            try {
                if (_.isEmpty(xhr.responseText)) {
                    message = `${message} ::  Empty Response`;
                } else {
                    const response = JSON.parse(xhr.responseText);
                    const remoteErrorMessage = response.message;
                    if (message) {
                        message = `${message} :: ${remoteErrorMessage}`;
                    } else {
                        message = remoteErrorMessage;
                    }

                    if (opts.showDescription && response.description) {
                        message = `${message} (${response.description})`;
                    }
                }
            } catch (e) {
                message = `${message} :: ${xhr.responseText}`;
            }
        }
        return message;
    };

    //
    // @Param Category - The top level Grouping
    // @Param Action - The Type of Event to track
    // @Param Label - Specific Details for the event (eg. ID, name)
    CC.utils.trackEvent = function(category, action, label) {
        try {
            CC.page.triggerGaEventTrack(category, action, label);
        } catch (err) {
            CC.utils.recordError(err.name, err.message);
        }
    };

    CC.utils.recordError = function(name, message, xhr) {
        // TODO: Should we alert DD? this is where were were alerting NewRelic
    };

    CC.utils.recordEvent = function(errorType, category, error, moreData) {
        // TODO: Should we alert DD? this is where were were alerting NewRelic
    };

    ////////////////////////////////////////////////////////////////////////////////////
    // Page Operations
    ////////////////////////////////////////////////////////////////////////////////////

    CC.page = {};

    // Ensure all parameters are set or the events will be ignored
    CC.page.triggerGaEventTrack = function(category, action, rawLabel) {
        // TODO: Potentially replace with Pendo
    };

    CC.page.redirect = function(redirectLocation) {
        return (window.location.href = redirectLocation);
    };

    CC.page.getClientChangeLink = function(clientId) {
        if (
            (CC.authorizeByPermission('Administration') || CC.SessionData.userOrgs.models.length > 1) &&
            CC.utils.getCurrentClientId() != clientId
        ) {
            return CC.utils.addQueryParams(window.location.href, {
                currentClientId: clientId
            });
        } else {
            return undefined;
        }
    };

    ////////////////////////////////////////////////////////////////////////////////////
    // Model <-> View conversion Utilities
    ////////////////////////////////////////////////////////////////////////////////////

    // Anything that can be reused for converting between unformatted model data and formatted views could go here.

    CC.converters = {};

    // goes between MM/DD/YYYY and YYYYMMDD as an integer
    CC.converters.dateMDYtoDateInt = function(dir, v) {
        let convertedValue = v;
        if (convertedValue != null && typeof convertedValue != 'undefined') {
            convertedValue = convertedValue.trim();
            if (dir == 'ModelToView' && convertedValue.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
                //  MM/DD/YYYY to YYYYMMDD
                convertedValue = parseInt(
                    convertedValue.substr(6, 4) + convertedValue.substr(0, 2) + convertedValue.substr(3, 2),
                    10
                );
            } else if (dir == 'ViewToModel' && convertedValue.toString().match(/^\d{8}$/)) {
                //  MM/DD/YYYY to YYYY-MM-DD
                convertedValue = convertedValue.toString();
                convertedValue = `${convertedValue.substr(5, 2)}/${convertedValue.substr(8, 2)}/${convertedValue.substr(
                    0,
                    4
                )}`;
            }
        }
        return convertedValue;
    };

    CC.converters.dateYMDTimetoMDY = function(direction, value) {
        let convertedValue;
        if (value != null && typeof value != 'undefined') {
            convertedValue = value;
            if (direction == 'ModelToView') {
                // YYYY-MM-DD + time to MM/DD/YYYY
                let month = (convertedValue.getMonth() + 1).toString();
                // string manipulation for a poor man's zerofill
                month = `0${month}`.substr(month.length - 1, 2);

                let day = convertedValue.getDate().toString();
                day = `0${day}`.substr(day.length - 1, 2);

                const year = convertedValue.getFullYear().toString();

                convertedValue = `${month}/${day}/${year}`;
            } else if (direction == 'ViewToModel') {
                //  MM/DD/YYYY to MM/DD/YYYY + Time and timezone
                const timestr = convertedValue.toTimeString();
                const offset = convertedValue.getTimezoneOffset();
                let absVal = parseInt(Math.abs(offset / 60), 10).toString();
                if (absVal.length < 2) {
                    absVal = `0${absVal}`;
                }
                let absTimeVal = (Math.abs(offset % 60), 2).toString();
                if (absTimeVal.length < 2) {
                    absTimeVal = `0${absTimeVal}`;
                }
                const timezoneOffset = (offset < 0 ? '+' : '-') + absVal + absTimeVal;
                convertedValue = `${timestr.substr(0, 5)} ${timezoneOffset}`;
            }
        }
        return convertedValue;
    };

    CC.converters.dateYMD = function(direction, value) {
        if (value != null && typeof value != 'undefined') {
            let convertedValue = value;
            if (direction == 'ModelToView') {
                // Date object to YYYY-MM-DD
                let month = (convertedValue.getMonth() + 1).toString();
                // string manipulation for a poor man's zerofill
                month = `0${month}`.substr(month.length - 1, 2);

                let day = convertedValue.getDate().toString();
                day = `0${day}`.substr(day.length - 1, 2);

                const year = convertedValue.getFullYear().toString();

                convertedValue = `${year}-${month}-${day}`;
            }
            return convertedValue;
        }
    };

    /**
     *
     * @param {Number} number
     * @param {Text} abbreviation tiny|standard (default)
     * @param {Boolean} asText html by default, otherwise just text
     * @returns a formatted number
     */
    CC.converters.formatNamedNumber = function(number, abbreviation, asText) {
        if (!CC.utils.hasValue(number)) {
            return number;
        }

        if (Math.abs(number) > 100000) {
            const friendlyNumber = CC.utils.friendly_number(number, 1, true, 100000, abbreviation);
            if (abbreviation === 'standard' || _.isUndefined(abbreviation)) {
                const pieces = friendlyNumber.split(' ');
                if (['thousand', 'million', 'billion', 'trillion'].indexOf(pieces[1]) !== -1) {
                    if (asText) {
                        return `${pieces[0]} ${pieces[1].capitalize()}`;
                    } else {
                        return `${pieces[0]}<span class="friendly-standard"> ${pieces[1]}</span>`;
                    }
                }
            }
            return friendlyNumber;
        }
        return this.formatNumber(number);
    };

    //Format the numbers to add comma as thousands separator
    CC.converters.formatNumber = function(x) {
        x = x.toString();
        const pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x)) {
            x = x.replace(pattern, '$1,$2');
        }
        return x;
    };

    /**
     * Formats a number of bytes into a human readable string with appropriate size units
     * @param {number} bytes - The number of bytes to format
     * @param {number} decimals - Number of decimal places to show (default 2)
     * @returns {string} Formatted string with size unit (e.g. "1.50 MB")
     */
    CC.converters.formatBytes = function(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    // Format a number to a given decimal spot. Will round correctly and always return exactly the number of
    // decimalPlaces places
    // Ex: num=1233.45,decimalPlaces=5 returns 1233.45000
    // Ex: num=1233.45,decimalPlaces=1 return 1233.5
    // Ex: num=1233.45,deciamlPlaces=0 return 1233
    // Return value is a string
    CC.converters.formatDecimalPlaces = function(num, decimalPlaces) {
        return Number(`${Math.round(parseFloat(`${num}e${decimalPlaces}`))}e-${decimalPlaces}`).toFixed(decimalPlaces);
    };

    // Format currency
    // Lifted from Widgets, originally done as Number.prototype.formatNumber
    // * @param number to be formatted
    // * @param decimalPlaces
    // * @param decimalSeparator
    // * @param thousandsSeparator
    // *  Based on http://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-money-in-javascript
    // *  Use: (123123.1212).formatNumber([number], '2', '.', ',') => "123,123.12"
    CC.converters.formatCurrency = function(number, decimalPlaces, decimalSeparator = '.', thousandsSeparator = ',') {
        const myDecimalPlaces = isNaN((decimalPlaces = Math.abs(decimalPlaces))) ? 0 : decimalPlaces;
        const sign = number < 0 ? '-' : '';
        const wholeNumber = `${parseInt((number = Math.abs(+number || 0).toFixed(decimalPlaces)), 10)}`;
        let j;
        const decimals = (j = wholeNumber.length) > 3 ? j % 3 : 0;
        const formattedValue =
            sign +
            (decimals ? wholeNumber.substring(0, decimals) + thousandsSeparator : '') +
            wholeNumber.substring(decimals, wholeNumber.length).replace(/(\d{3})(?=\d)/g, `$1${thousandsSeparator}`) +
            (myDecimalPlaces
                ? decimalSeparator +
                  Math.abs(number - wholeNumber)
                      .toFixed(myDecimalPlaces)
                      .slice(2)
                : '');
        return formattedValue;
    };

    CC.converters.formatStats = function(number, opts) {
        const options = Object.assign(
            {
                decPlaces: 1,
                precision: false,
                roundTo: 1000,
                abbreviationType: 'tiny',
                asHtml: false,
                default: '0'
            },
            opts
        );
        let formattedNumber = CC.utils.addCommas(number);
        if (options.abbreviationType === 'none') {
            if (!options.precision) {
                formattedNumber = CC.utils.friendly_number(number, 0, false, options.roundTo, 'tiny');
            }
        } else {
            formattedNumber = CC.utils.friendly_number(
                number,
                options.decPlaces,
                options.precision,
                options.roundTo,
                options.abbreviationType
            );
        }
        if (options.asHtml) {
            if (options.abbreviationType === 'standard' || _.isUndefined(options.abbreviationType)) {
                const pieces = String(formattedNumber).split(' ');
                if (['thousand', 'million', 'billion', 'trillion'].indexOf(pieces[1]) !== -1) {
                    return `<span class="friendly-number__value"> ${pieces[0] ||
                        options.default}</span><span class="friendly-number__abbreviation"> ${pieces[1]}</span>`;
                } else {
                    return `<span class="friendly-number__value"> ${formattedNumber || options.default}</span>`;
                }
            } else {
                return `<span class="friendly-number__value"> ${formattedNumber || options.default}</span>`;
            }
        } else {
            return formattedNumber || options.default;
        }
    };

    // Brought over from Widgets. Uses old Model binder format (NOT the lib itself)
    // Used explicitly on the Add Data Revenue page, we should consider this as deprecated moving forward.
    CC.converters.currency = function(direction, value) {
        let convertedValue;
        if (value != null && typeof value != 'undefined') {
            if (direction == 'ModelToView' && parseFloat(value, 10) >= 0) {
                // 12345.6 to $12,345.60, $0 allowed
                convertedValue = `$${CC.converters.formatCurrency(parseFloat(value), 2, '.', ',', 10)}`;
            } else if (direction == 'ViewToModel') {
                //  $12,345.60 to 12345.6
                convertedValue = $.trim(value)
                    .replace('$', '')
                    .replace(',', '');
            }
        }
        return convertedValue;
    };

    // go from e.g. $123,456.78 to "123456.78". Really just wraps formatCurrency
    // above with some handling for going M<->V.
    // Note that '$' is hardcoded, in case we ever go international.
    CC.converters.convertCPM = function(direction, value) {
        if (direction !== 'ViewToModel') {
            direction = 'ModelToView';
        }
        let convertedValue;
        if (value != null && typeof value != 'undefined') {
            if (direction == 'ModelToView') {
                const parsedValue = parseFloat(value, 10);
                const decimalPlaces = 2;
                if (!isNaN(parsedValue)) {
                    convertedValue = `$${CC.converters.formatCurrency(value, decimalPlaces)}`;
                } else {
                    convertedValue = '';
                }
            } else {
                convertedValue = '';
                if (typeof value === 'string') {
                    convertedValue = value.replace(/[\s\$\,]/g, '');
                }
            }
        }
        return convertedValue;
    };

    /**
     * Having a specified style of grammar.
     * Helper function to make it easier to use proper grammar in messages
     *
     * Example
     *  CC.converters.grammared()`Yes, Delete [this|these] ${[flattened.length]} model[|s]`
     *  1 => Yes, Delete this model
     *  2+ => Yes, Delete these models
     *
     * @returns https://github.com/broofa/simplur
     */
    CC.converters.grammared = function() {
        return simplur;
    };

    // This is really a template helper. Contains inline style and markup, sue me - MBM
    // Given a path as an array of node(behavior) names, generate the output string.
    CC.converters.formatPath = function(path, delimiter) {
        const preMarkup = '<span style="white-space:nowrap;">'; // do this to prevent wrapping on node
        const postMarkup = '</span>\r\n'; // cr/lf else won't wrap between nodes
        let formattedPath = '';
        if (typeof delimiter == 'undefined') {
            delimiter = ' &rarr; '; // configuration?
        }
        delimiter += postMarkup + preMarkup;

        if (_.isArray(path)) {
            formattedPath = preMarkup + path.map(p => _.escape(p)).join(delimiter) + postMarkup;
        }
        return formattedPath;
    };

    CC.converters.formatPaths = function(paths) {
        const formattedPaths = [];
        if (_.isArray(paths)) {
            _.each(
                paths,
                function(path) {
                    formattedPaths.push(CC.converters.formatPath(path));
                },
                this
            );
        }
        return formattedPaths;
    };

    // Template helper. Given a string representation of a Date,
    // format it to a consistent format. This could eventually go away
    // in favor of Javascript Date manipulation, but this is a short-term
    // solution to keep things consistent, and allow us to apply user preferences
    // in one place should we decide to do so.
    // Currently supports formats:
    //   YYYYMMDD, YYYY-MM-DD, MM/DD/YYYY[optional time]
    // Currently outputs (always): MM/DD/YYYY
    CC.converters.formatCompactDateStr = function(dateStr) {
        dateStr = dateStr.trim();
        let formattedDateStr = dateStr;
        const pattern1 = /^\d{8}$/;
        const pattern2 = /^\d{4}\-\d{2}\-\d{2}$/;
        const pattern3 = /^\d{2}\/\d{2}\/\d{4}/;
        let applyChanges = false;
        let year;
        let month;
        let day;
        if (pattern1.test(dateStr)) {
            applyChanges = true;
            year = dateStr.substr(0, 4);
            month = dateStr.substr(4, 2);
            day = dateStr.substr(6, 2);
        } else if (pattern2.test(dateStr)) {
            applyChanges = true;
            year = dateStr.substr(0, 4);
            month = dateStr.substr(5, 2);
            day = dateStr.substr(8, 2);
        } else if (pattern3.test(dateStr)) {
            // this is destructive in that you lose the optional time component - just sayin'
            applyChanges = true;
            year = dateStr.substr(6, 4);
            month = dateStr.substr(0, 2);
            day = dateStr.substr(3, 2);
        }
        // done this way so the final formatting is in one place
        if (applyChanges) {
            formattedDateStr = `${month}/${day}/${year}`;
        }
        return formattedDateStr;
    };

    CC.converters.reportPercentage = function(dir, v) {
        if (dir !== 'ViewToModel') {
            dir = 'ModelToView';
        }
        if (v != null && typeof v != 'undefined') {
            let convertedValue = v;
            if (dir == 'ModelToView') {
                if (v < 0.1) {
                    convertedValue = '< 0.1';
                } else {
                    convertedValue = Math.round(v, -1).toString();
                }
            }
            return convertedValue;
        }
    };

    // helper function
    $.fn.serializeObject = function() {
        const obj = {};
        const arr = this.serializeArray();
        $.each(arr, function() {
            if (obj[this.name]) {
                if (!obj[this.name].push) {
                    obj[this.name] = [obj[this.name]];
                }
                obj[this.name].push(this.value || '');
            } else {
                obj[this.name] = this.value || '';
            }
        });
        return obj;
    };

    CC.getCurrentLocationPath = function() {
        return window.location.pathname;
    };
    CC.setCurrentLocationPath = function(path) {
        window.location.pathname = path;
    };
    CC.getCurrentLocationPathWithQueryParams = function() {
        return window.location.pathname + window.location.search;
    };
    // This function will edit the current url but not add another entry to the history
    CC.removeQueryParamSilently = function(textToReplace) {
        history.replaceState({}, '', document.location.pathname.replace(textToReplace, ''));
    };
    CC.changeDocumentTitle = function(newTitle) {
        if (_.isString(newTitle) && newTitle.length > 0) {
            document.title = `${newTitle} - Lotame`;
        } else {
            document.title = 'Lotame';
        }
    };

    CC.authorizeByPermission = function(permission) {
        const result = CC.getAuthorizedClientsForPermission(permission);
        return !_.isUndefined(result) && result.length > 0;
    };
    CC.getAuthorizedClientsForPermission = function(permission) {
        // TODO: Make sure this does something reasonable for cascading permissions.
        const clients = [];
        // handling for case where no one is logged in. May remove and streamline that path
        if (typeof CC.SessionData.userInfo === 'undefined') {
            return [];
        }

        const clientRoleSet = CC.SessionData.userInfo.get('clientRoleSet');

        if (!_.isUndefined(clientRoleSet)) {
            if (!_.isUndefined(clientRoleSet[0])) {
                //permissions = clientRoleSet[0].role.permissions;
                _.each(clientRoleSet, function(crs) {
                    const clientPermissions = crs.role.permissions;
                    const hasPermission = _.findWhere(clientPermissions, { name: permission });
                    if (!_.isUndefined(hasPermission)) {
                        clients.push(parseInt(crs.client.id, 10));
                    }
                });
            } else {
                console.error(`Empty client role set found while looking for permission: ${permission}`);
                return [];
            }
        } else {
            console.error(`No client role set found while looking for permission: ${permission}`);
            return [];
        }

        return clients;
    };
    CC.authorizeByPermissionAndClient = function(permission, clientId) {
        const hasDataOverride = CC.SessionData.userInfo.hasDataOverride();
        if (hasDataOverride === true) {
            return CC.authorizeByPermission(permission);
        }
        const clients = CC.getAuthorizedClientsForPermission(permission);
        return _.contains(clients, parseInt(clientId, 10));
    };
    CC.isActiveUserInternalUser = function() {
        const username = CC.SessionData.userInfo.get('username');
        return username.endsWith('@lotame.com') || username.endsWith('@crwdcntrl.net');
    };
    /**
     * Get a configuration value or use the supplied default
     * NOTE: All configuration values return as strings
     *    to ensure consistent performance, default value should be a string
     * configName : (i.e - enableMergeAudiences)
     * defaultValue : 'false'
     * @return - configValue, defaultValue, or undefined
     */
    CC.getConfigSetting = function(configName, defaultValue) {
        const configValue = CC.configData[configName];
        return _.isUndefined(configValue) ? defaultValue : configValue;
    };
    /**
     * Get a configuration value as an int or use the supplied int default
     * configName : (i.e - behaviorTypeId)
     * defaultIntValue : 123
     * @return - configValue, defaultIntValue, or undefined
     */
    CC.getConfigSettingAsInt = function(configName, defaultIntValue) {
        const configValue = CC.configData[configName];

        return _.isUndefined(configValue) ? defaultIntValue : parseInt(configValue, 10);
    };
    /**
     * Clear the account info stored in local storage
     * @return VOID
     */
    CC.clearSession = function() {
        localStorage.removeItem(`Account_${CC.utils.getUserMemberClientId()}`);
        localStorage.removeItem(`Account_${CC.utils.getUserMemberClientId()}_name`);
        localStorage.removeItem(`Account_${CC.utils.getUserMemberClientId()}_id`);
    };

    CC.utils.getPageScheme = function() {
        return CC.pageScheme;
    };

    CC.utils.isUmbra = function() {
        return CC.utils.getPageScheme() === 'page-scheme-umbra';
    };
    /**
     * Wait for supplied xhrs to complete
     * @param  {Array} xhrs              A list of active xhrs
     * @param  {Function} completionFn   Optional function to call upon completion
     * @param  {Function} translateXhrFn Optional function to call when showing errors to help describe the errors
     * @return {Promise}                 Promise that will resolve/reject when all calls complete
     */
    CC.utils.waitForXhrs = function(xhrs, completionFn, translateXhrFn) {
        const deferred = $.Deferred();
        const errors = [];
        translateXhrFn =
            translateXhrFn ||
            function(xhr, payload) {
                return _.isUndefined(payload) ? undefined : payload.id;
            };

        if (_.isUndefined(xhrs) || xhrs.length === 0) {
            // Abort because there is nothing to do
            deferred.resolve.apply(null, ['Nothing to do']);
        } else {
            // Completion function to call when all xhrs are completed
            const onComplete = _.after(xhrs.length, function(data, textStatus, jqXHR) {
                // Optional custom Logic
                if (_.isFunction(completionFn)) {
                    completionFn.apply(this, [data, textStatus, jqXHR, errors]);
                }

                // Automatically update the promise
                if (errors.length > 0) {
                    deferred.reject.apply(null, [
                        {
                            responses: _.map(errors, function(error) {
                                let payload;
                                try {
                                    payload = JSON.parse(error.xhr.originalRequestOptions.data);
                                } catch (error) {
                                    // Ignore this error
                                }
                                return CC.utils.getAjaxErrorMessage(
                                    error.xhr,
                                    translateXhrFn.apply(this, [xhrs[error.i], payload])
                                );
                            })
                        }
                    ]);
                } else {
                    deferred.resolve.apply(null, arguments);
                }
            });

            // Add a handler to each xhr
            _.each(xhrs, function(xhr, i) {
                xhr.fail(function(xhr, textStatus, errorThrown) {
                    errors.push({
                        xhr: xhr,
                        textStatus: textStatus,
                        errorThrown: errorThrown,
                        i: i
                    });
                }).always(onComplete); // data|jqXHR, textStatus, jqXHR|errorThrown
            });
        }

        // Return a promise
        return deferred;
    };

    /**
     * Borrowed from backbone-validation, used as an equivalent for required: true
     * @param  {?} value The item to check
     * @return {Boolean} Whether or not the parameter has a value
     */
    CC.utils.hasValue = function(value) {
        return !(
            _.isNull(value) ||
            _.isUndefined(value) ||
            (_.isString(value) && $.trim(value) === '') ||
            (_.isArray(value) && _.isEmpty(value))
        );
    };

    /**
     * Gets a property value from an object, handling both regular properties and function properties.
     * If the property is a function, it will be executed with the item as its argument.
     * If the property doesn't exist or is undefined, returns the default value.
     *
     * @param {Object} item - The source object to retrieve the property from
     * @param {string} prop - The property name to look up
     * @param {*} defaultValue - The value to return if the property is undefined
     * @param {...*} args - Additional arguments to pass to the function property if it exists
     * @returns {*} The property value, result of function execution, or default value
     */
    CC.utils.getResult = function(item, prop, defaultValue, ...args) {
        const value = item[prop];
        return _.isFunction(value) ? value.call(item, ...args) : value ?? defaultValue;
    };

    CC.utils.cleanString = function(rawString) {
        return CC.utils.hasValue(rawString) ? rawString.trim() : rawString;
    };

    CC.utils.passwordValidator = function(value, attr, customValue, model) {
        if (!value) {
            return 'invalid password';
        }
        /**
         * Valid passwords have at least three of the following
         *    1. lower case character
         *    2. upper case character
         *    3. number
         *    4. symbol
         *  Plus, nothing repeats more than 3 times.
         * UNLESS it's more than 20 characters. Then, anything goes.
         */
        const lowerCasePatt = value.search(/[a-z]/g);
        const upperCasePatt = value.search(/[A-Z]/g);
        const numberPatt = value.search(/[0-9]/g);
        const symbolPatt = value.search(/[\W]/g);
        const repeatPatt = value.search(/(\w|\W)\1{3,}/g);

        let i = 0;
        if (lowerCasePatt >= 0) {
            i++;
        }
        if (upperCasePatt >= 0) {
            i++;
        }
        if (numberPatt >= 0) {
            i++;
        }
        if (symbolPatt >= 0) {
            i++;
        }

        if (!((i >= 3 && repeatPatt < 0) || value.length > 20)) {
            return 'invalid password';
        }
    };

    return CC;
});
