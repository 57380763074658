define(function(require) {
    const BasicFilterTemplate = require('./BasicFilter.template.html');
    const BaseView = require('app/Base.view');
    const _ = require('underscore');

    const BasicFilterView = BaseView.extend({
        template: _.template(BasicFilterTemplate), // Custom template
        defaults: {
            type: 'query',
            name: 'q', // The query param name
            queryParams: [],
            stateParam: undefined,
            placeholder: 'Search for...', // The HTML5 placeholder for input boxes
            collection: undefined, // The PageableCollection that is to be filtered
            templateParams: {}, // Addtional template params for the custom template
            convertBooleans: false,
            convertToNumber: false,
            filterHandler: undefined, // A custom filter handler to use instead of a collection
            clearHandler: undefined // A custom clear handler to use instead of a collection
        },

        events: {
            'click .change-filter': 'filter',
            'click .clear-filter': 'clear',
            'change input:not([type=search])': 'searchRealTime',
            'input input[type=search]': 'searchRealTime'
        },

        initialize: function(options) {
            const self = this;

            _.extend(self, self.defaults, options);
        },

        searchRealTime: _.debounce(function() {
            // Make sure the dom is still around since
            // this executes asynchronously
            if (this.$el) {
                this.filter();
            }
        }, 500),

        //
        // Override this to return the value of the query param
        // to be used for filtering
        getFilterValue: function() {
            const self = this;
            const value = self.$('.filter').val();
            return value;
        },

        reset: function() {
            const self = this;
            let value;
            switch (this.type) {
                case 'query':
                    value = self.$('.filter').val(null);
                    break;
            }
            return value;
        },

        prepareParams: function(rawValue) {
            const self = this;
            let value = rawValue;
            // It's either connected to the state params or the query params
            if (!_.isUndefined(self.stateParam)) {
                value = _.isArray(value) ? _.first(rawValue) : rawValue;
                if (self.convertToNumber && !isNaN(value)) {
                    value = +value;
                }

                const newState = {};
                newState[self.stateParam] = value;
                const state = _.extend(self.collection.state, newState);

                if (['sortKey', 'order'].indexOf(self.stateParam) !== -1) {
                    // Make sure sorting is changed properly
                    self.collection.setSorting(state.sortKey, state.order);
                } else if (self.stateParam === 'pageSize') {
                    self.collection.setPageSize(value, {
                        deferFetch: true
                    });
                }
            } else {
                if (self.convertToNumber && !isNaN(rawValue)) {
                    value = +rawValue;
                }
                self.convertValueToQueryParams(value);
            }
        },

        /**
         * Update the query params on the collection
         * @param {String} value
         */
        convertValueToQueryParams: function(value) {
            const self = this;
            _.each(self.queryParams, function(queryParam) {
                self.collection.queryParams[queryParam] = value;
            });
        },

        filter: function(e) {
            if (e) {
                e.preventDefault();
            }

            if (_.isFunction(this.filterHandler)) {
                this.filterHandler(this.getFilterValue());
                this.showClearButtonMaybe();
                return;
            }

            if (_.isUndefined(this.collection)) {
                // Filtering is not set up
                return;
            }

            const data = {};
            let query = this.getFilterValue();
            if (_.isUndefined(query) || (_.isString(query) && _.isEmpty(query))) {
                this.clear();
                return;
            }

            if (this.convertBooleans) {
                query = query ? 'Y' : 'N';
            }

            const collection = this.collection;

            // Make sure the links for infinite mode are reset
            if (collection.mode === 'infinite') {
                this.xhr = collection.switchMode('infinite', {
                    resetState: true,
                    fetch: false
                });
            }

            // go back to the first page on search
            if (collection.getFirstPage) {
                this.prepareParams(query);

                this.xhr = collection.getFirstPage({
                    reset: true,
                    fetch: true,
                    traditional: true // Multiple values do not have the brackets (eg. Prevent labelId[]: <value>)
                });
            } else {
                data[this.name] = query;
                this.xhr = collection.fetch({
                    data: data,
                    reset: true,
                    traditional: true // Multiple values do not have the brackets (eg. Prevent labelId[]: <value>)
                });
            }

            this.showClearButtonMaybe();
            this.collection.trigger('BaseFilter:filter', query);
        },

        abort: function() {
            if (this.xhr) {
                this.xhr.abort();
            }
        },

        clear: function() {
            const self = this;

            if (_.isFunction(this.clearHandler)) {
                this.clearHandler();
                this.reset();
                this.showClearButtonMaybe();
                return;
            }

            if (_.isUndefined(this.collection)) {
                // Filtering is not set up
                return;
            }

            self.reset();

            const collection = this.collection;

            // go back to the first page on clear
            if (collection.getFirstPage) {
                _.each(this.queryParams, function(queryParam) {
                    delete collection.queryParams[queryParam];
                });
                collection.getFirstPage({
                    reset: true,
                    fetch: true,
                    traditional: true // Multiple values do not have the brackets (eg. Prevent labelId[]: <value>)
                });
            } else {
                collection.fetch({
                    reset: true,
                    traditional: true // Multiple values do not have the brackets (eg. Prevent labelId[]: <value>)
                });
            }
            self.showClearButtonMaybe();
            self.collection.trigger('BaseFilter:clear');
        },

        set: function(newValue, isSilent) {
            const mySearchFilter = this.$('input');
            mySearchFilter.val(newValue);
            if (!isSilent) {
                mySearchFilter.trigger('input');
            } else {
                this.showClearButtonMaybe();
            }
        },

        focus: function() {
            this.$('input').trigger('focus');
        },

        render: function() {
            const self = this;
            const viewOptions = _.extend(
                {
                    name: self.name,
                    type: self.type,
                    label: self.label,
                    placeholder: self.placeholder
                },
                self.templateParams
            );

            self.$el.html(this.template(viewOptions));
            self.showClearButtonMaybe();

            return self;
        },

        showClearButtonMaybe: function() {
            const $clearButton = this.$('.clear');
            const searchTerms = this.getFilterValue();
            if (searchTerms) {
                $clearButton.show();
            } else {
                $clearButton.hide();
            }
        }
    });

    // Ensure the events and defaults extend to all children
    BasicFilterView.extend = function(child) {
        const view = BaseView.extend.apply(this, arguments);
        view.prototype.events = _.extend({}, this.prototype.events, child.events);
        view.prototype.defaults = _.extend({}, this.prototype.defaults, child.defaults);
        return view;
    };

    return BasicFilterView;
});
