export default class Logger {
    constructor(name = 'UI') {
        this.name = name;
    }

    get debuggingEnabled() {
        return window.location.href.indexOf('lotameDebug=true') !== -1;
    }

    get logStyle() {
        return 'color:lime;background:black;padding:4px 6px;border-radius:3px;';
    }
    get errorStyle() {
        return 'color:red;background:black;padding:4px 6px;border-radius:3px;';
    }

    isDebugEnabled() {
        return this.debuggingEnabled;
    }

    /**
     * Formats a message for console logging. This should be paired with parameter that specified console style
     * (ie: to correspond to the %c)
     * @param {string} message
     * @returns {string}
     */
    formatConsoleMessage(message) {
        return `%c${this.name}: ${message}`;
    }

    debug(debugMessage, debugObject) {
        if (this.debuggingEnabled && debugMessage) {
            if (debugObject) {
                console.groupCollapsed(this.formatConsoleMessage(debugMessage), this.logStyle, debugObject);
                console.trace();
                console.groupEnd();
            } else {
                console.groupCollapsed(this.formatConsoleMessage(debugMessage), this.logStyle);
                console.trace();
                console.groupEnd();
            }
        }
    }

    trace(debugMessage, debugObject) {
        if (this.debuggingEnabled && debugMessage) {
            if (debugObject) {
                console.trace(this.formatConsoleMessage(debugMessage), this.logStyle, debugObject);
            } else {
                console.trace(this.formatConsoleMessage(debugMessage), this.logStyle);
            }
        }
    }

    error(errorMessage, errorObject) {
        if (errorMessage) {
            if (errorObject) {
                console.error(this.formatConsoleMessage(errorMessage), this.errorStyle, errorObject);
            } else {
                console.error(this.formatConsoleMessage(errorMessage), this.errorStyle);
            }
        }
    }
}
