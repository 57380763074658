define(function(require) {
    const CC = require('CC');
    const BasicModel = require('models/app/BasicModel');
    const Feature = BasicModel.extend(
        {
            idAttribute: 'featureId',

            urlRoot: function() {
                return `${CC.apiUrl}/security/features`;
            },

            toJSON: function(options) {
                const modelJson = BasicModel.prototype.toJSON.apply(this, arguments);
                delete modelJson.enabled;
                return modelJson;
            }
        },
        {
            RolloutStatusOptions: [
                {
                    id: 'PREVIEW',
                    name: 'Preview',
                    title: 'Only available to Lotame Employees. Use <code>with_feature</code> parameter.',
                    summary: 'Only available to Lotame Employees. Use <code>with_feature=<featureName></code>.'
                },
                {
                    id: 'ALPHA',
                    name: 'Alpha',
                    title: 'Available to any user <b>regardless of client</b>' +
                           ' who has &quot;Experimental UI Features&quot; turned on',
                    summary: 'Available to any user <span class="font-italics">regardless of client</span>' +
                             ' who has "Experimental UI Features" turned on'
                },
                {
                    id: 'BETA',
                    name: 'Beta',
                    title: 'Talk to Product First! Enabled when turned on for a client on their Manage Features Tab',
                    summary: 'Talk to Product First! Enabled when turned on for a client on their Manage Features Tab'
                },
                {
                    id: 'GA',
                    name: 'GA',
                    title: 'Enabled when turned on for a client on their Manage Features Tab',
                    summary: 'Enabled when turned on for a client on their Manage Features Tab'
                },
                {
                    id: 'INTEGRATED',
                    name: 'Integrated',
                    title: 'Available to all client ids. Feature is ready to be removed',
                    summary: 'Available to all client ids. Feature is ready to be removed'
                }
            ]
        }
    );
    return Feature;
});
