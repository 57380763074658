const Backbone = require('backbone');
const CC = require('CC');
const Client = require('models/client/Client');

const userOrgsCollection = Backbone.Collection.extend({
    model: Client,
    url: function() {
        return `${CC.apiUrl}/clients/assigned`;
    },

    parse: function(response) {
        return response.clients;
    }
});
export default userOrgsCollection;
